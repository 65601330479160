import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { googleAuthAction } from "../redux/auth/action/GoogleLoginActions";
import { toast } from "react-toastify";

export const useLogin = () => {
    const dispatch = useDispatch();

    return useGoogleLogin({
        onSuccess: async (tokenResponse) => {
          const { access_token } = tokenResponse;
          console.log('Access Token:', access_token);
          dispatch(googleAuthAction(tokenResponse)); // Unique Google action for User End
        },
        onError: error => toast.error("Google Login Failed"),
        scope: 'openid email profile',
      });
}