// src/redux/auth/action/filtersReducer.js

const initialState = {
    name: "",
    category: "",
    dateRange: {
      start: null,
      end: null,
    },
    startTime: "",
    endTime: "",
    location: "",
    ticketPriceMin: "",
    ticketPriceMax: "",
    isFreeTicket: false,
    capacityMin: "",
    capacityMax: "",
  };
  
  const filtersReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_FILTERS':
        return {
          ...state,
          ...action.payload,
        };
      case 'RESET_FILTERS':
        return initialState;
      default:
        return state;
    }
  };
  
  export default filtersReducer;
  