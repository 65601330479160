import {
    SET_SELECTED_TICKET,
    RESET_SELECTED_TICKET,
    SET_SELECTED_QUANTITY,
} from '../constant/selectedTicketConstants';

export const setSelectedTicket = (ticket) => ({
    type: SET_SELECTED_TICKET,
    payload: ticket
});

export const resetSelectedTicket = () => ({
    type: RESET_SELECTED_TICKET
});

export const setSelectedQuantity = (quantity) => ({
    type: SET_SELECTED_QUANTITY,
    payload: quantity,
  });