import React from 'react'
import Shimmer from '../../../component/Shimmer'
import { Link } from 'react-router-dom'
import { IoPersonCircleSharp } from 'react-icons/io5'
import { navAnchorLinks } from '../../../assets/Link/navbarLinks'

function ProfileLinks({loading, firstName}) {
  return (
    <div className="flex items-center space-x-4">
        <a
            href={navAnchorLinks[1].link}
            target={navAnchorLinks[1].target}
            rel={navAnchorLinks[1].rel}
            className="font-poppins text-base text-black font-semibold ml-2"
        >
            {navAnchorLinks[1].title}
        </a>

        {loading && <Shimmer width="45px" height="20px" borderRadius="rounded" />}
        {!loading && firstName && 
            <Link to="/dashboard" className="flex items-center ml-4">
                <IoPersonCircleSharp
                    size={25}
                    className="cursor-pointer text-black"
                />
                <p className="ml-1 font-poppins text-base text-nowrap text-black font-medium">
                    {firstName}
                </p>
            </Link>}
        {!loading && !firstName && 
            <Link to="/login" className="flex items-center ml-4">
                <IoPersonCircleSharp
                    size={25}
                    className="cursor-pointer text-black"
                />
                <p className="ml-1 font-poppins text-base text-nowrap text-black font-medium">
                    Login
                </p>
            </Link>}
    </div>
  )
}

export default ProfileLinks
