import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { getUserTickets } from "../../../redux/auth/action/userTicketActions";
import PurchasedTicketModal from "./PurchasedTicketModal";
import CardSlider from "../../../component/Events/CardSlider";

const PurchaseHistory = () => {
  const dispatch = useDispatch();

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  // Fetch tickets from Redux store
  const { tickets, loading, error } = useSelector((state) => state.ticketList);
  const isPurchaseHistory = true;

  useEffect(() => {
    dispatch(getUserTickets());
  }, [dispatch]);

  /**
   * Handles the click event on a ticket card.
   * Opens the PurchasedTicketModal with the selected ticket's details.
   *
   * @param {Object} ticket - The ticket object that was clicked.
   */
  const handleTicketClick = (ticket) => {
    setSelectedTicket(ticket);
    setIsModalOpen(true);
  };

  /**
   * Closes the PurchasedTicketModal and clears the selected ticket.
   */
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTicket(null);
  };

  return (
    <>
      {loading && (
        <CardSlider
          loading={loading}
          isPurchaseHistory={isPurchaseHistory}
        />
      )}

      {!loading && tickets && tickets.length > 0 && (
        <>
          <CardSlider
            loading={loading}
            data={tickets}
            isPurchaseHistory={isPurchaseHistory}
            onCardClick={isPurchaseHistory ? handleTicketClick : null}
          />
          <PurchasedTicketModal
            ticket={selectedTicket}
            isOpen={isModalOpen}
            onClose={handleCloseModal}
          />
          {error && <div className="text-red-500">{error}</div>}
        </>
      )}

      {!loading && (!tickets || tickets.length === 0) && (
        <div className="flex flex-col h-full items-center justify-center text-3xl text-gray-500 italic opacity-80">
          <p>You haven't purchased any tickets</p>
          <p>
            You can find tickets by going to the{" "}
            <Link className="text-blue-500 underline" to="/">
              Home Page
            </Link>
          </p>
        </div>
      )}
    </>
  );

};

export default PurchaseHistory;