// src/redux/auth/action/LoginAction.jsx

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../constant/LoginConstant";
import apiClient from "../../../apiClient/apiClient";
import axios from "axios"; // Import axios

// Create an axios instance without interceptors for token refresh
const refreshClient = axios.create({
  baseURL: process.env.REACT_APP_API_CLIENT_BASE_URL,
});

// Action creators
export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});
export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});
export const logout = () => {
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");
  sessionStorage.removeItem("access");
  sessionStorage.removeItem("refresh");
  return { type: LOGOUT };
};

// Login User
export const loginUser = (values, remember = false) => async (dispatch) => {
  dispatch(loginRequest());
  try {
    // Clear existing tokens to avoid conflicts
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    sessionStorage.removeItem("access");
    sessionStorage.removeItem("refresh");

    // Attempt to log in
    const response = await apiClient.post("login/", values);
    const { access, refresh } = response.data;

    if (access && refresh) {
      // Fetch user profile to verify role
      const config = {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      };

      const responseUserProfile = await apiClient.get("user-profile/", config);

      console.log("[LOGIN DEBUG] User Profile Response:", responseUserProfile);

      if (responseUserProfile.status === 200) {
        // Store tokens based on "Remember Me" checkbox
        if (remember) {
          localStorage.setItem("access", access);
          localStorage.setItem("refresh", refresh);
          console.log("Tokens stored in localStorage");
        } else {
          sessionStorage.setItem("access", access);
          sessionStorage.setItem("refresh", refresh);
          console.log("Tokens stored in sessionStorage");
        }

        dispatch(loginSuccess({ access, refresh }));
        console.log("[TESTING] Login successful");
        // Do not redirect here
      }
    } else {
      throw new Error("Invalid response from the server");
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { data } = error.response;

      console.log("[LOGIN ERROR] Error Response:", error);

      if (data.email) {
        toast.error("No active account with this email");
      } else if (error.response.data.detail === "User Profile Not Found for this user") {
        toast.warning(
          <div>
            It looks like you're an organizer. You can log in on the organizer end from{" "}
            <a
              className="underline text-blue-500"
              href="https://organizer.eventfullsunday.co.uk/login"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            to add or edit events, or{" "}
            <button
              className="underline text-blue-500"
              onClick={async () => {
                try {
                  // Remove any stored token to avoid issues with login
                  localStorage.removeItem("access");
                  localStorage.removeItem("refresh");
                  sessionStorage.removeItem("access");
                  sessionStorage.removeItem("refresh");

                  const response = await apiClient.post("login/", values);
                  const { access, refresh } = response.data;

                  if (remember) {
                    localStorage.setItem("access", access);
                    localStorage.setItem("refresh", refresh);
                    console.log("Tokens stored in localStorage");
                  } else {
                    sessionStorage.setItem("access", access);
                    sessionStorage.setItem("refresh", refresh);
                    console.log("Tokens stored in sessionStorage");
                  }

                  dispatch(loginSuccess({ access, refresh }));
                  console.log("[TESTING] Login successful, user logged in anyway");
                  toast.success("Logged in successfully!");
                } catch (err) {
                  toast.error("Failed to log in");
                  console.error("Error logging in anyway:", err);
                }
              }}
            >
              Log In
            </button>{" "}
            anyway.
          </div>,
          {
            autoClose: 8000,
          }
        );
      } else if (data.password) {
        toast.error("Invalid password");
      } else {
        toast.error(data.detail || "An error occurred. Please try again later.");
      }
    } else {
      toast.error("An error occurred. Please try again later.");
    }
    dispatch(loginFailure("Invalid email or password"));
  }
};

// Refresh Access Token
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const refreshAccessToken = () => async (dispatch) => {
  try {
    if (isRefreshing) {
      // Return a promise that resolves once the token is refreshed
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      });
    }

    isRefreshing = true;

    const refreshToken = localStorage.getItem("refresh") || sessionStorage.getItem("refresh");

    if (!refreshToken) throw new Error("No refresh token available");

    const response = await refreshClient.post("/token/refresh/", { refresh: refreshToken });
    const { access: newAccessToken, refresh: newRefreshToken } = response.data;

    // Store the new tokens in the appropriate storage
    if (localStorage.getItem("refresh")) {
      localStorage.setItem("access", newAccessToken);
      localStorage.setItem("refresh", newRefreshToken);
    } else if (sessionStorage.getItem("refresh")) {
      sessionStorage.setItem("access", newAccessToken);
      sessionStorage.setItem("refresh", newRefreshToken);
    }

    // Dispatch the new tokens to Redux store
    dispatch(
      loginSuccess({
        access: newAccessToken,
        refresh: newRefreshToken,
      })
    );

    processQueue(null, newAccessToken); // Resolve all pending requests with new access token
    console.log("[TESTING] Token refreshed successfully");
    isRefreshing = false;
    return newAccessToken;
  } catch (error) {
    processQueue(error, null);
    isRefreshing = false;

    // Handle failure (log out, clear tokens, etc.)
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    sessionStorage.removeItem("access");
    sessionStorage.removeItem("refresh");
    dispatch(logout());

    console.log("[TESTING] Refresh token failed, user logged out:", error);
    return Promise.reject(error);
  }
};


// Logout User
export const logoutUser = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("access") || sessionStorage.getItem("access");
    const refresh = localStorage.getItem("refresh") || sessionStorage.getItem("refresh");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (refresh) {
      const response = await apiClient.post("logout/", { refresh }, config);

      if (response.status === 205) {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        sessionStorage.removeItem("access");
        sessionStorage.removeItem("refresh");
        dispatch(logout());
        // window.location.href = "/";
        console.log("[TESTING] Logged out successfully!");
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } else {
      console.log("No refresh token found.");
    }
  } catch (error) {
    console.error("Logout error:", error);
  }
};
