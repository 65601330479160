
import { combineReducers } from "redux";
import loginReducer from "./auth/reducer/LoginReducer";
import registerReducer from "./auth/reducer/RegisterReducer";
import passwordReducer from "./auth/reducer/ForgotPasswordReducer";
import { profileReducer, sendResetVerificationEmailReducer } from "./auth/reducer/ProfileReducer";
import organizationReducer from "./auth/reducer/OrganizationProfileReducer";
import passwordResetReducer from "./auth/reducer/passwordResetReducer";
import getCreateEventReducer from "./createEvent/createEventReducer/GetCreateEventReducer";
import { eventDetailsReducer } from "./auth/reducer/EventDetailReducer";
import { ticketListReducer, singleUserTicketReducer } from "./auth/reducer/userTicketReducer";
import { fetchTicketReducer } from "./auth/reducer/fetchTicketsReducer";
import googleLoginReducer from "./auth/reducer/GoogleLoginReducer";
import { subscriptionReducer } from "./auth/reducer/SubscriptionReducer";
import selectedTicketReducer from "./auth/reducer/selectedTicketReducer";
import filtersReducer from "./auth/reducer/filtersReducer";

// Import redux-persist utilities
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { wishlistReducer } from "./auth/reducer/fetchWishlistReducer";

// Define persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["profile", "selectedTicket", "eventItems"], // Specify reducers to persist
};

// Combine your reducers
const rootReducer = combineReducers({
  login: loginReducer,
  passwordForgot: passwordReducer,
  passwordResetDetail: passwordResetReducer,
  register: registerReducer,
  profile: profileReducer,
  resetVerification: sendResetVerificationEmailReducer,
  organization: organizationReducer,
  getEvent: getCreateEventReducer,
  eventItems: eventDetailsReducer,
  ticketList: ticketListReducer,
  singleUserTicket: singleUserTicketReducer,
  fetchTicket: fetchTicketReducer,
  googleLogin: googleLoginReducer,
  subscriptionDetail: subscriptionReducer,
  selectedTicket: selectedTicketReducer,
  filters: filtersReducer,
  wishlist: wishlistReducer,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
