import React from 'react'
import { navAnchorLinks, navRouterLinks } from '../../../assets/Link/navbarLinks'
import { Link } from 'react-router-dom'

function AuthLinks() {
  return (
    <div className="flex items-center space-x-3 ms-2">
        {navAnchorLinks.map((authlink) => {
            const Icon = authlink.icon
            return (<a 
                href={authlink.link} 
                target={authlink.target} 
                rel={authlink.rel} 
                className='font-poppins text-base text-black font-semibold'
            >
                <span className={`flex items-center ${authlink?.fontType}`}>{Icon && <Icon size={25} className="text-black me-1" />}
                {authlink.title}</span>
            </a>)
        })}
        {navRouterLinks.map((authlink) => 
            <Link 
                to={authlink.link} 
                className="font-poppins text-base text-black font-semibold"
            >
                {authlink.title}
            </Link>)}
    </div>
  )
}

export default AuthLinks
