/**
 * Truncates a string to a specified number of words and appends an ellipsis if necessary.
 *
 * @param {string} text - The text to truncate.
 * @param {number} maxWords - The maximum number of words allowed before truncation.
 * @returns {string} - The truncated text with an ellipsis if truncation occurred.
 */
export const truncateText = (text, maxWords) => {
    if (!text) return '';
    const words = text.split(' ');
    if (words.length <= maxWords) return text;
    return words.slice(0, maxWords).join(' ') + '...';
  };