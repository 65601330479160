// ProfileUpdateModal.jsx
import React from "react";
import Modal from "react-modal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  IoPersonCircle,
} from "react-icons/io5";
import { MdOutlineMailOutline, MdClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { updateUserProfile } from "../../../redux/auth/action/userProfileActions";
import 'react-phone-number-input/style.css';
import PhoneInput from "react-phone-number-input";

Modal.setAppElement("#root"); // Set the root element for accessibility

const ProfileUpdateModal = ({ isOpen, onRequestClose, initialValues }) => {
  const dispatch = useDispatch();
  console.log(initialValues);

  const handleUpdate = (values, { setSubmitting }) => {
    console.log(values);
    
      // Create FormData object to hold the profile data
      const formData = new FormData();
      formData.append('full_name', values.full_name);
      formData.append('email', values.email);
      formData.append('phone_number', values.phone_number);
      
    dispatch(updateUserProfile(formData));
    setSubmitting(false);
    onRequestClose(); // Close the modal after updating
  };

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Full name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, "Phone number must be only digits")           //International E.164 phone number
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must be at most 15 digits"),
      //.required("Phone number is required"),
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="flex justify-center items-center p-6"
      overlayClassName="fixed inset-0 bg-gray-600 bg-opacity-75"
    >
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md relative">
        <button 
          onClick={onRequestClose} 
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <MdClose className="w-6 h-6" />
        </button>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleUpdate}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="w-full">
              <p className="text-2xl font-semibold text-center mb-6">Update Profile</p>
              <div className="mb-4">
                <label htmlFor="full_name" className="block mb-2 text-sm font-medium">Full Name</label>
                <div className="relative">
                  <IoPersonCircle className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                  <Field
                    id="full_name"
                    name="full_name"
                    type="text"
                    placeholder="Full Name"
                    className="w-full p-3 pl-10 border-2 border-sunday_secondary rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                  />
                </div>
                <ErrorMessage name="full_name" component="div" className="text-red-500 text-sm mt-1" />
              </div>
            
              <div className="mb-4">
                <label htmlFor="email" className="block mb-2 text-sm font-medium">Email</label>
                <div className="relative">
                  <MdOutlineMailOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="w-full p-3 pl-10 border-2 border-sunday_secondary rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                  />
                </div>
                <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              <div className="mb-4">
                <label htmlFor="phone_number" className="block mb-2 text-sm font-medium">Phone Number</label>
                <div className="relative flex items-center">
                  {/*<IoCallOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                  <span className="absolute left-10 top-1/2 transform -translate-y-1/2 text-gray-500">+44</span>
                  <Field
                    type="tel"
                    name="phone_number"
                    placeholder="Phone Number"
                    className="w-full p-3 pl-16 border-2 border-sunday_secondary rounded-full focus:outline-none focus:ring-2 focus:ring-[#1399FF]"
                    style={{ paddingLeft: '70px' }}
                  />*/}
                  <PhoneInput
                  placeholder="Phone Number"
                  defaultCountry="GB"
                  value={values.phone_number}
                  international
                  countryCallingCodeEditable={false}
                  onChange={phoneNumber => setFieldValue("phone_number", phoneNumber)}
                  className="w-full p-3 border-2 border-sunday_secondary rounded-full outline-none" />
                </div>
                <ErrorMessage name="phone_number" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              <div className="flex justify-center mb-4">
                <button type="submit" className="w-full p-3 bg-sunday_primary hover:brightness-95 transition duration-200 text-white rounded-full focus:outline-none">
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ProfileUpdateModal;
