// src/components/FavoriteButton.jsx
import React, { useEffect, useState } from 'react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import apiClient from '../../apiClient/apiClient';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getWishlistAfterDelete } from '../../redux/auth/action/fetchWishlist';

const FavoriteButton = ({ inside, eventId, isWishlist }) => { // Destructure the prop correctly

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isFavorite, setIsFavorite] = useState(isWishlist);

    const request = {
        event_id: eventId
    }

    useEffect(() => {
        setIsFavorite(isWishlist);
    }, [isWishlist]);

    const token = localStorage.getItem("access") || sessionStorage.getItem("access");
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

    const handleClick = (e) => {
        e.preventDefault();    // Prevent the default Link behavior
        e.stopPropagation();   // Stop the event from bubbling up

        if(!token) {
            navigate('/login');
            return;
        }
        const newFavorite = !isFavorite;
        setIsFavorite(newFavorite);
        try {
            if(newFavorite) {
                const fave = apiClient.post('/wishlist/', request, config).then((res) => console.log(res)).catch((err) => console.log(err));
            } else {
                const unfave = apiClient.delete('/wishlist/', { data: request, ...config}).then((res) => console.log(res)).catch((err) => console.log(err));
                dispatch(getWishlistAfterDelete(eventId));
            }
        } catch(error) {
            console.error("Error updating favorite status:", error);
            toast.error(error.message);
        }
    };

    return (
        <button
            onClick={handleClick}
            className={
                `flex items-center justify-center w-8 h-8 z-20 sm:bg-white rounded-full sm:shadow-md hover:bg-gray-100 transition-colors
                 ${inside ? "" : "sm:absolute sm:right-2 sm:bottom-2"}`
            }
            aria-label={isFavorite ? "Remove from favorites" : "Add to favorites"}
        >
            {isFavorite ? <FaHeart className="text-sunday_warning" /> : <FaRegHeart className="text-gray-500" />}
        </button>
    );
};

export default FavoriteButton;
