import {
  GET_CREATE_EVENT_ITEMS_FAILURE,
  GET_CREATE_EVENT_ITEMS_REQUEST,
  GET_CREATE_EVENT_ITEMS_SUCCESS,
} from "../createConstant/GetCreateConsant";

const initialState = {
  loading: false,
  data: [],
  error: null,
};

const getCreateEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CREATE_EVENT_ITEMS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_CREATE_EVENT_ITEMS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GET_CREATE_EVENT_ITEMS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export default getCreateEventReducer;
