// src/redux/auth/reducer/selectedTicketReducer.jsx

import { SET_SELECTED_TICKET, RESET_SELECTED_TICKET, SET_SELECTED_QUANTITY } from "../constant/selectedTicketConstants";

const initialState = {
  selectedTicket: null,
  ticketQuantity: 0, // Initialize quantity to 0 (no selection)
};

const selectedTicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_TICKET:
      if (action.payload === null) {
        // If payload is null, reset the state
        return initialState;
      }
      return {
        ...state,
        selectedTicket: action.payload,
        ticketQuantity: 1, // Start with quantity 1 when a new ticket is selected
      };
    case SET_SELECTED_QUANTITY:
      if (action.payload === 0) {
        // If quantity is set to 0, reset the state
        return initialState;
      }
      return {
        ...state,
        ticketQuantity: action.payload,
      };
    case RESET_SELECTED_TICKET:
      // Explicitly reset the state
      return initialState;
    default:
      return state;
  }
};

export default selectedTicketReducer;
