import { PASSWORD_RESET_REQUEST, PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAILURE } from '../constant/ForgotConstant'

const initialState = {
  isAuthenticated: false,
  user: null,
  access: null,
  refresh: null,
  loading: false,
  error: null,
  message: null,
};

const passwordReducer = (state = initialState, action) => {
  switch (action.type) {
    case PASSWORD_RESET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        access: action.payload.access,
        refresh: action.payload.refresh,
        user: action.payload.user,
      };
    case PASSWORD_RESET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default passwordReducer;
