// src/redux/createEvent/createActions/fetchGetItems.js

import apiClient from "../../../apiClient/apiClient";
import {
  GET_CREATE_EVENT_ITEMS_FAILURE,
  GET_CREATE_EVENT_ITEMS_REQUEST,
  GET_CREATE_EVENT_ITEMS_SUCCESS
} from "../createConstant/GetCreateConsant";

/**
 * Action creator to fetch events and assign category names to each event.
 */
export const fetchGetItems = () => async (dispatch) => {
  dispatch({
    type: GET_CREATE_EVENT_ITEMS_REQUEST
  });

  try {
    // Retrieve the access token from localStorage
    const token = localStorage.getItem("access") || sessionStorage.getItem("access");

    // Configure headers if token exists
    const config = token
      ? {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
      : {};

    // Fetch events and categories concurrently using Promise.all
    const [responseEvents, responseCategories] = await Promise.all([
      apiClient.get("/events/"),
      apiClient.get("/category/")
    ]);

    const events = responseEvents.data; // Assuming responseEvents.data is an array of events
    const categories = responseCategories.data; // Assuming responseCategories.data is an array of categories

    // Create a mapping from category_id to category_name
    const categoryMap = {};
    categories.forEach(category => {
      categoryMap[category.category_id] = category.category_name;
    });

    // Assign category_name to each event based on category_id
    const enhancedEvents = events.map(event => ({
      ...event,
      category_name: categoryMap[event.category] || "Unknown Category" // Fallback if category_id not found
    }));

    console.log("[Enhanced Events]:", enhancedEvents);
    

    // Dispatch the success action with enhanced events
    dispatch({
      type: GET_CREATE_EVENT_ITEMS_SUCCESS,
      payload: enhancedEvents
    });

  } catch (error) {
    // Handle errors and dispatch failure action
    dispatch({
      type: GET_CREATE_EVENT_ITEMS_FAILURE,
      payload: error.response?.data?.message || error.message
    });
  }
};
