export const footerLinks = [
    {
        link: 'https://events.eventfullsunday.co.uk/about-us/',
        title: 'About us'
    },
    {
        link: 'https://events.eventfullsunday.co.uk/terms-conditions/',
        title: 'Terms and conditions'
    },
    {
        link: 'https://events.eventfullsunday.co.uk/privacy-policy/',
        title: 'Privacy Policy'
    },
    {
        link: 'https://events.eventfullsunday.co.uk/',
        title: 'Contact us'
    }
];