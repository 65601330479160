import { ORGANIZATION_PROFILE_RESET_FAILURE, ORGANIZATION_PROFILE_RESET_REQUEST, ORGANIZATION_PROFILE_RESET_SUCCESS } from "../constant/OrganizationProfileConstant"



const initialState={
    token:null,
    profile:null,
    loading:false,
    error:null
}

const organizationReducer=(state=initialState,action)=>{
  switch(action.type){
    case ORGANIZATION_PROFILE_RESET_REQUEST:
        return{
            ...state,
            loading:true,
            error:null
        }
    case ORGANIZATION_PROFILE_RESET_SUCCESS:
        return{
            ...state,
            loading:false,
            profile:action.payload
        }
    case ORGANIZATION_PROFILE_RESET_FAILURE:
        return {
            ...state,
            loading:false,
            error:action.payload
        }
    default:
        return state
  }
}
export default organizationReducer