import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slickBanner.css";
import Shimmer from '../../../component/Shimmer';
import apiClient from '../../../apiClient/apiClient';
import { useDispatch } from 'react-redux';
import { setFilters } from '../../../redux/auth/action/filtersActions';

function SlickBanner({ events }) {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventId, setEventId] = useState();
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState();

  const dispatch = useDispatch();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
    fade: false
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await apiClient.get("/category/");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, [categoryId]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await apiClient.get("api/image-upload/");
        if (response.data.length > 0) {
          const bannerData = response.data.sort((a, b) => 
            new Date(b.updated_at) - new Date(a.updated_at)
          );
          setImages([
            bannerData[0]?.slider_1,
            bannerData[0]?.slider_2,
            bannerData[0]?.slider_3,
            bannerData[0]?.slider_4,
            bannerData[0]?.slider_5
          ].filter(Boolean));
          setEventId(bannerData[0].event_id);
          setCategoryId(bannerData[0].category_id);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching images:', error);
        setLoading(false);
      }
    };
    fetchImages();
  }, []);

  const handleCategoryClick = (categoryName) => {
    dispatch(setFilters({ category: categoryName }));
  };

  if (loading) {
    return <Shimmer width="100%" height="400px" />;
  }

  return (
    <div className="banner-wrapper">
      <div className="banner-container">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="banner-slide">
              <div className="banner-content">
                {index === 0 && (
                  <>
                    <h1 className="banner-title">
                      Connecting Communities, Celebrating Traditions
                    </h1>
                    <p className="banner-subtitle">
                      From Food Fests to Traditional Dance, Find It Here
                    </p>
                    <button 
                      className="banner-cta-button"
                      onClick={() => handleCategoryClick('cultural')}
                    >
                      Join the Celebration!
                    </button>
                  </>
                )}
                {index === 1 && (
                  <>
                    <h1 className="banner-title">
                      Discover Live Music
                    </h1>
                    <p className="banner-subtitle">
                      Experience Artists From Around the World
                    </p>
                    <button 
                      className="banner-cta-button"
                      onClick={() => handleCategoryClick('music')}
                    >
                      Get Tickets Now!
                    </button>
                  </>
                )}
                {index === 2 && (
                  <>
                    <h1 className="banner-title">
                      Comedy Nights
                    </h1>
                    <p className="banner-subtitle">
                      Laugh Out Loud with the Funniest Acts!
                    </p>
                    <button 
                      className="banner-cta-button"
                      onClick={() => handleCategoryClick('comedy')}
                    >
                      Book Your Seats
                    </button>
                  </>
                )}
                {index === 3 && (
                  <>
                    <h1 className="banner-title">
                      Professional Growth
                    </h1>
                    <p className="banner-subtitle">
                      Learn, Network, Grow!
                    </p>
                    <button 
                      className="banner-cta-button"
                      onClick={() => handleCategoryClick('business')}
                    >
                      Explore Career Events
                    </button>
                  </>
                )}
                {index === 4 && (
                  <>
                    <h1 className="banner-title">
                      Festivals & Carnivals
                    </h1>
                    <p className="banner-subtitle">
                      Experience the Magic of Live Events
                    </p>
                    <button 
                      className="banner-cta-button"
                      onClick={() => handleCategoryClick('festival')}
                    >
                      Find Events
                    </button>
                  </>
                )}
              </div>
              <div className="banner-image-container">
                <img 
                  src={image} 
                  alt={`Event banner ${index + 1}`} 
                  className="banner-image"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default SlickBanner;