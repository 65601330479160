import { GET_WISHLIST_AFTER_DELETE, GET_WISHLIST_FAILURE, GET_WISHLIST_REQUEST, GET_WISHLIST_SUCCESS } from "../constant/fetchWishlistConstant"

const initialState = {
    wishlist: null,
    loading: false,
    error: null
}

export const wishlistReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_WISHLIST_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case GET_WISHLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                wishlist: action.payload,
            }
        case GET_WISHLIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_WISHLIST_AFTER_DELETE:
            return {
                ...state,
                loading: false,
                wishlist: state.wishlist.filter((item) => item.event !== action.payload)
           }
        default:
            return state;
    }
}