import {
    SUBSCRIPTION_REQUEST, SUBSCRIPTION_SUCCESS, SUBSCRIPTION_FAILURE
  } from "../constant/SubscriptionConstants";
  
  const initialState = {
    loading: false,
    data: null,
    error: null
  };
  
  export const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
      case SUBSCRIPTION_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case SUBSCRIPTION_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
        };
  
      case SUBSCRIPTION_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };