// src/component/Events/CardSlider.jsx

import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PlaceholderCard from './PlaceholderCard';
import PropTypes from 'prop-types';
import { truncateText } from './truncateText';
import FavoriteButton from './FavoriteButton';
import { useDispatch, useSelector } from 'react-redux';
import { getWishlist } from '../../redux/auth/action/fetchWishlist';

/**
 * CardSlider Component
 *
 * Displays a slider of event or user ticket cards. Depending on the `isPurchaseHistory` prop,
 * clicking on a card will either navigate to the event details page or open a modal with ticket details.
 *
 * @param {boolean} loading - Indicates if the data is still loading.
 * @param {Array} data - Array of event or user ticket objects.
 * @param {boolean} isPurchaseHistory - Flag to determine the context of the slider.
 * @param {Function} onCardClick - Function to handle card clicks when in PurchaseHistory.
 */
const CardSlider = ({
  loading = false,
  data = [],
  isPurchaseHistory = false,
  onCardClick = null,
}) => {
  const sliderRef = useRef(null);

  const token = localStorage.getItem("access") || sessionStorage.getItem("access");
  const dispatch = useDispatch();
  const { wishlist } = useSelector(state => state.wishlist);


  useEffect(() => {
    const slider = sliderRef.current;

    if (!slider) return;

    const handleWheel = (e) => {
      const { scrollWidth, clientWidth, scrollLeft } = slider;
      const isOverflowing = scrollWidth > clientWidth;

      if (isOverflowing) {
        e.preventDefault();
        slider.scrollLeft += e.deltaY;
      }
    };

    // Add wheel event listener with passive: false
    slider.addEventListener('wheel', handleWheel, { passive: false });

    // Clean up the event listener on component unmount
    return () => {
      slider.removeEventListener('wheel', handleWheel);
    };
  }, []);

  useEffect(() => {
    dispatch(getWishlist());
  }, [dispatch]);
  /**
   * Formats the ticket price.
   * @param {string|number} price - The price string or number from the ticket data.
   * @returns {string} - Formatted price string.
   */
  const formatPrice = (price) => {
    if (price === 'Price not set') return 'Free';

    if (price) {
      const priceRange = price.split("-");
      const firstPrice = parseFloat(priceRange[0].replace("£", ""));
      const secondPrice = priceRange[1] ? parseFloat(priceRange[1].replace("£", "")) : null;

      const isRange = priceRange.length === 2 && firstPrice !== secondPrice;
      const isUpTo = price.includes("Up to");
      const isSame = priceRange.length === 1 || firstPrice === secondPrice;

      if (isRange) {
        return `From £${firstPrice}`;
      } else if (isUpTo) {
        return `${priceRange[0]}`;
      } else if (isSame) {
        return `£${firstPrice}`;
      }
    } else { return "N/A" }
  };

  return (
    <div className="w-full mb-5">
      <div
        ref={sliderRef}
        className={`${isPurchaseHistory
          ? 'grid gap-6 lg:gap-7 py-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 mx-0'
          : 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 lg:gap-7 mx-3 py-6'
          }`}
      >
        {loading
          ? Array(3)
            .fill(0)
            .map((_, index) => (
              <PlaceholderCard
                isPurchaseHistory={isPurchaseHistory}
                key={index}
              />
            ))
          : data.length > 0 ? (
            <>
              {/* {
                Array(3)
                  .fill(0)
                  .map((_, index) => (
                    <PlaceholderCard
                      isPurchaseHistory={isPurchaseHistory}
                      key={index}
                    />
                  ))
              } */}
              {data.map((EventData, index) => {
                /**
                 * Handles the click event on a card.
                 * Opens the modal if in PurchaseHistory; otherwise, navigates to event details.
                 */
                const handleClick = () => {
                  if (isPurchaseHistory && onCardClick) {
                    onCardClick(EventData);
                  }
                };

                const isWishlist = wishlist?.some(item => item.event === EventData.event_id);

                return isPurchaseHistory ? (
                  <div
                    key={index}
                    onClick={handleClick}
                    className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-lg hover:scale-105 transition duration-200 flex flex-col h-full"
                  >
                    <div
                      className="relative w-full aspect-video bg-cover bg-center"
                      style={{
                        backgroundImage: `url(${EventData?.thumbnail_path || 'https://via.placeholder.com/300x200.png?text=No+Image'})`,
                      }}
                    />
                    <div className="flex flex-col flex-grow p-4">
                      <p
                        className="font-poppins text-lg sm:text-xl text-center font-bold mt-3"
                        title={EventData.event_name}
                      >
                        {truncateText(EventData.event_name, 8)} {/* maxWords before "..." can be adjusted */}
                      </p>
                      <p className="font-poppins text-sm text-center text-gray-600 mt-2">
                        Reference ID: {EventData.reference_id || 'N/A'}
                      </p>
                      <p className="font-poppins text-sm text-center text-gray-600 mt-1">
                        Category:{' '}
                        {EventData.ticket_category
                          ? EventData.ticket_category.charAt(0).toUpperCase() +
                          EventData.ticket_category.slice(1)
                          : 'N/A'}
                      </p>
                      <p className="font-poppins text-sm text-center text-gray-600 mt-1">
                        Quantity: {EventData.ticket_quantity || 'N/A'}
                      </p>
                      <p className="font-poppins text-base text-center font-bold mt-2">
                        {formatPrice(EventData.price_range)}
                      </p>
                    </div>
                  </div>
                ) : (
                  <Link to={`/event/${EventData.event_id}`} key={index}>
                    <div className="flex flex-row sm:flex-col relative rounded-lg overflow-hidden cursor-pointer hover:shadow-lg transition duration-200">

                      {/* Thumbnail Image */}
                      <div className="relative w-24 h-24 sm:w-full sm:h-auto sm:aspect-[1/1] flex-shrink-0">
                        <img
                          src={EventData?.thumbnail_path || 'https://via.placeholder.com/100'}
                          alt="Event Thumbnail"
                          className="w-full h-full object-cover rounded-lg aspect-[1/1]"
                        />
                        {/* Inside Favorite Button */}
                        <div className="hidden sm:flex items-center sm:absolute sm:right-2 sm:bottom-2 p-2">
                          <FavoriteButton inside={true} eventId={EventData.event_id} isWishlist={isWishlist} />
                        </div>
                      </div>

                      {/* Event Info */}
                      <div className="flex flex-col justify-between px-2 sm:p-4 flex-grow">
                        <div>
                          <p
                            className="font-poppins text-base sm:text-xl font-bold line-clamp-2"
                            title={EventData.event_name}
                          >
                            {/* {truncateText(EventData.event_name, maxWords)} */}
                            {EventData.event_name}
                          </p>
                          <p className="font-poppins text-sm text-black">
                            {new Date(EventData.start_date).toLocaleDateString('en-US', {
                              weekday: 'short',
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric'
                            })} at {EventData.start_time.slice(0, 5)}
                          </p>
                          <p className="font-poppins text-sm text-sunday_tertiary font-bold">
                            {EventData.venue_name || 'Venue Name'}
                          </p>
                          <p className="font-poppins text-sm text-black">
                            {formatPrice(EventData.price_range)}
                          </p>
                        </div>

                        {/* Get Tickets Button (Hidden on Mobile) */}
                        {/* <div className="hidden sm:block mt-2">
                              <button
                                className="py-1 px-4 bg-sunday_primary text-white font-semibold rounded-full hover:bg-sunday_primary_dark transition duration-200"
                              >
                                Get Tickets
                              </button>
                            </div> */}
                      </div>

                      {/* Outside Favorite Button */}
                      <div className="sm:hidden flex items-center p-2">
                        <FavoriteButton eventId={EventData.event_id} isWishlist={isWishlist} />
                      </div>
                    </div>
                  </Link>
                );
              })}
            </>
          ) : (
            <p className="text-center col-span-full text-gray-500">No events available.</p>
          )}
      </div>
    </div>
  );
};

// Prop type validation
CardSlider.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  onCardClick: PropTypes.func,
};

export default CardSlider;
