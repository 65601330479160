import { GOOGLE_LOGIN_FAILURE, GOOGLE_LOGIN_REQUEST, GOOGLE_LOGIN_SUCCESS } from "../constant/GoogleLoginConstants";

const initialState = {
    isAuthenticated: false,
    access: (localStorage.getItem('access') || sessionStorage.getItem('access')) || null,
    refresh: (localStorage.getItem('refresh') || sessionStorage.getItem('refresh')) || null,
    loading: null,
    error: null,
}

const googleLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case GOOGLE_LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GOOGLE_LOGIN_SUCCESS:
            return {
                ...state,
                access: action.payload.access,
                refresh: action.payload.refresh,
                loading: false,
                error: null,
            };
        case GOOGLE_LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export default googleLoginReducer;