import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { resetPassword } from '../../redux/auth/action/passwordResetActions';
import { useParams, useLocation } from 'react-router-dom';
import {
    IoEyeOutline,
    IoEyeOffOutline,
    IoLockClosedOutline,
} from "react-icons/io5";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LeftSection from '../../component/LeftSection';
import user from '../../assets/images/user.png';
import logo from '../../assets/images/eventfullsunday.png';


const ResetPassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();

    // Extract query parameters
    const searchParams = new URLSearchParams(location.search);
    const uidb64 = searchParams.get("uid");
    const token = searchParams.get("token");

    const togglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(prevShowConfirmPassword => !prevShowConfirmPassword);
    };

    const initialValues = {
        newPassword: '',
        confirmNewPassword: ''
    };

    const validationSchema = Yup.object({
        newPassword: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('Password is required'),
        confirmNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            .required('Confirm your password')
    });

    const handlePasswordReset = (values, { setSubmitting }) => {
        dispatch(resetPassword(uidb64, token, values.newPassword, values.confirmNewPassword))
            .catch(() => {
                toast.error((<p className="m-2">{`There was a problem with your request. Please try again later.`}</p>));
            });
        setSubmitting(false);
    };

    return (
        <div className="flex flex-col lg:flex-row h-screen">
            {/* Left Section */}
            <LeftSection
                logo={logo}
                mainImage={user}
                mainText="Eventfull Sundays"
            />

            {/* Right Section: Reset Password Form */}
            <div className="flex justify-center items-center w-full lg:w-1/2 bg-white p-6 lg:p-8">

                {/* Code to test whether the toast notification works properly */}

                {/* <div className="flex justify-center mb-4">
                <button onClick={() => { toast.success(<p className="m-2">You've successfully updated your password, you'll be redirected to the login page shortly.</p>) }} className="w-full p-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600">
                    Toast test
                </button>
            </div> */}

                <div className="bg-white p-6 lg:p-8 rounded-lg shadow-lg w-full max-w-md">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handlePasswordReset}
                        validateOnChange={true}  // Validate as user types
                        validateOnBlur={false}   // Disable validation on blur
                    >
                        {({ handleSubmit, handleBlur, handleChange, values, isValid, isSubmitting }) => (
                            <Form onSubmit={handleSubmit} className="w-full">
                                <p className="text-2xl font-semibold text-center mb-6">Reset Password</p>

                                <div className="mb-4">
                                    <label htmlFor="newPassword" className="block mb-2 text-sm font-medium">New Password</label>
                                    <div className="relative">
                                        <IoLockClosedOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                                        <Field
                                            type={showPassword ? "text" : "password"}
                                            id="newPassword"
                                            name="newPassword"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.newPassword}
                                            placeholder="••••••••"
                                            className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600"
                                        />
                                        <button type="button" onClick={togglePasswordVisibility} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                                            {!showPassword ? <IoEyeOffOutline className="w-5 h-5" /> : <IoEyeOutline className="w-5 h-5" />}
                                        </button>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="confirmNewPassword" className="block mb-2 text-sm font-medium">Confirm New Password</label>
                                    <div className="relative">
                                        <IoLockClosedOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                                        <Field
                                            type={showConfirmPassword ? "text" : "password"}
                                            id="confirmNewPassword"
                                            name="confirmNewPassword"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.confirmNewPassword}
                                            placeholder="••••••••"
                                            className="w-full p-3 pl-10 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600"
                                        />
                                        <button type="button" onClick={toggleConfirmPasswordVisibility} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                                            {!showConfirmPassword ? <IoEyeOffOutline className="w-5 h-5" /> : <IoEyeOutline className="w-5 h-5" />}
                                        </button>
                                    </div>
                                    {/* Display validation message */}
                                    <ErrorMessage name="confirmNewPassword" component="div" className="text-red-600 text-sm mt-2" />
                                    <ErrorMessage name="newPassword" component="div" className="text-red-600 text-sm mt-2" />
                                </div>

                                <div className="flex justify-center mb-4">
                                    <button type="submit" className="w-full p-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600">
                                        Reset Password
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <ToastContainer position="top-right" autoClose={99999} />
                </div>
            </div>
        </div >
    );
};

export default ResetPassword;
