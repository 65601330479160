import React, { useState } from 'react'
import { CiLocationOn } from 'react-icons/ci';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import Shimmer from '../../component/Shimmer';
import { FaBicycle, FaBus, FaCar, FaWalking } from 'react-icons/fa';

function LocationSection({ data }) {

    const [showMap, setShowMap] = useState(false);
    const [mapLoading, setMapLoading] = useState(false);

    // Construct location query for Google Maps
  const locationQuery = `${data?.venue_name || ""} ${data?.venue_address || ""} ${data?.venue_post_code || ""}`;

  return (
    locationQuery && 
    <div className="mt-7">
        <p className="text-xl font-semibold mb-2">Location</p>

        {/* Full Address with Icon */}
        <div className={`flex items-start text-gray-700 ${showMap || (!data?.extra_image && !data?.extra_image2) ? "mb-4" : "mb-0"}`}>
            <CiLocationOn size={24} className="mr-2" />

            <div className="flex flex-col gap-y-1">
                <span className="block">
                    <strong>
                        {data?.venue_name || 'TBA'}
                    </strong>
                </span>
                <span className="block">
                    {[data?.venue_address, data?.event_origin_country, data?.venue_post_code].filter(Boolean).join(', ') || ''}
                </span>
                {/* Show/Hide Map Button */}
                <button
                onClick={() => {
                    setShowMap(!showMap);
                    if (!showMap) {
                        setMapLoading(true); // Set loading to true when showing the map
                    }
                }}
                className="flex items-center text-blue-500 hover:underline focus:outline-none"
                >
                    {showMap ? 'Hide Map' : 'Show Map'}
                    {showMap ? (
                    <IoChevronUpOutline size={20} className="ml-1" />
                    ) : (
                    <IoChevronDownOutline size={20} className="ml-1" />
                    )}
                </button>
            </div>
        </div>

        {/* Map and Navigation Buttons */}
        {showMap && (
        <>
            {/* Shimmer while map is loading */}
            {mapLoading && (
            <div className="w-full aspect-video md:w-[720px] md:aspect-[16/9] mb-4">
                <Shimmer width="100%" height="100%" borderRadius="rounded-xl" />
            </div>
            )}

            {/* Map iframe */}
            <div className={`w-full aspect-video md:w-[720px] md:aspect-[16/9] mb-4 ${mapLoading ? 'hidden' : 'block'}`}>
                <iframe
                title="Event Location Map"
                className="w-full h-full"
                frameBorder="0"
                style={{ border: 0 }}
                src={`https://maps.google.com/maps?q=${encodeURIComponent(locationQuery)}&output=embed`}
                allowFullScreen
                onLoad={() => setMapLoading(false)} // Set loading to false once the map loads
                ></iframe>
            </div>

            {/* Navigation Buttons */}
            <div className="flex justify-around w-full md:w-[720px]">
                <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(locationQuery)}&travelmode=driving`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center text-gray-700 rounded-lg pt-2 px-2 pb-1 button-hover-effect"
                >
                    <FaCar size={24} />
                    <span>Drive</span>
                </a>
                <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(locationQuery)}&travelmode=transit`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center text-gray-700 rounded-lg pt-2 px-2 pb-1 button-hover-effect"
                >
                    <FaBus size={24} />
                    <span>Transit</span>
                </a>
                <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(locationQuery)}&travelmode=walking`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center text-gray-700 rounded-lg pt-2 px-2 pb-1 button-hover-effect"
                >
                    <FaWalking size={24} />
                    <span>Walk</span>
                </a>
                <a
                href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(locationQuery)}&travelmode=bicycling`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center text-gray-700 rounded-lg pt-2 px-3 pb-1 button-hover-effect"
                >
                    <FaBicycle size={24} />
                    <span>Bike</span>
                </a>
            </div>
        </>
        )}
    </div>
  )
}

export default LocationSection
