import React, { useState } from 'react';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

function MiddleBanner() {
  const [showPopup, setShowPopup] = useState(false);

  const handleAppClick = (e) => {
    e.preventDefault();
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 3000);
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full relative flex flex-col md:flex-row justify-between items-center rounded-lg mb-4 mx-auto overflow-hidden"
      style={{ 
        background: 'linear-gradient(135deg, #FED7B6 0%, #FFB088 100%)',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }}
    >
      {/* Left Section */}
      <div className='flex-1 p-4 md:p-8'>
        <motion.div
          initial={{ x: -20 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="space-y-3 md:space-y-4"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            className="inline-flex items-center gap-2 px-4 py-2 bg-white/90 backdrop-blur-sm 
                       rounded-full shadow-md border border-[#EA6F33]/20 mb-4"
          >
            <span className="flex items-center gap-1.5">
              <span className="relative flex h-2 w-2">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#EA6F33] opacity-75"></span>
                <span className="relative inline-flex rounded-full h-2 w-2 bg-[#EA6F33]"></span>
              </span>
              <span className="text-sm font-semibold bg-gradient-to-r from-[#EA6F33] to-[#072436] bg-clip-text text-transparent">
                Coming Soon
              </span>
            </span>
            <span className="text-xs text-gray-600 border-l border-gray-200 pl-2">
              iOS & Android
            </span>
          </motion.div>
          
          <h2 className='text-[#072436] text-2xl md:text-4xl font-bold leading-tight'>
            Where Communities<br/>Come Alive
          </h2>
          
          <p className='text-[#072436] text-base md:text-lg opacity-90 max-w-md'>
            Experience the magic of local events. Connect, celebrate, and create unforgettable moments with your community.
          </p>
        </motion.div>
      </div>

      {/* Right Section */}
      <div className="flex-1 p-4 md:p-8">
        <motion.div
          initial={{ x: 20 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="flex flex-col items-center md:items-end"
        >
          <p className="text-[#072436] font-poppins text-lg font-semibold mb-3">
            Join the Waitlist
          </p>
          
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              href="#"
              onClick={handleAppClick}
              className="flex items-center justify-center gap-2 bg-[#072436] text-white px-4 py-2 rounded-lg hover:bg-[#0a3450] transition-all duration-300 w-full sm:w-auto"
            >
              <FaGooglePlay size={20} />
              <div className="flex flex-col">
                <span className="text-xs">GET IT ON</span>
                <span className="text-sm font-semibold">Google Play</span>
              </div>
            </motion.a>

            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              href="#"
              onClick={handleAppClick}
              className="flex items-center justify-center gap-2 bg-[#072436] text-white px-4 py-2 rounded-lg hover:bg-[#0a3450] transition-all duration-300 w-full sm:w-auto"
            >
              <FaApple size={20} />
              <div className="flex flex-col">
                <span className="text-xs">Download on the</span>
                <span className="text-sm font-semibold">App Store</span>
              </div>
            </motion.a>
          </div>
        </motion.div>
      </div>

      {/* Coming Soon Popup */}
      <AnimatePresence>
        {showPopup && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className="fixed bottom-4 right-4 left-4 md:left-auto md:right-10 bg-[#072436] text-white px-4 py-3 rounded-lg shadow-lg z-50 text-center md:text-left"
          >
            <p className="text-sm md:text-base">
              🎉 Be the first to experience the future of community events!
            </p>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Decorative Elements */}
      <div className="absolute -bottom-10 -left-10 w-32 h-32 md:w-40 md:h-40 rounded-full bg-[#EA6F33] opacity-10 blur-xl"></div>
      <div className="absolute -top-10 -right-10 w-32 h-32 md:w-40 md:h-40 rounded-full bg-[#EA6F33] opacity-10 blur-xl"></div>
    </motion.div>
  );
}

export default MiddleBanner;
