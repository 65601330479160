// Profile.jsx
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="w-full bg-white p-6 rounded-lg shadow-lg font-poppins">
      <div className="flex flex-col text-justify">
        {/*<a 
        href="https://events.eventfullsunday.co.uk/privacy-policy/" 
        target="_blank" rel="noopener noreferrer" className="mt-4 text-blue-500 hover:underline">
        View Privacy Policy here</a>*/}
      
      <h1 className="text-3xl font-bold mb-6 flex items-center text-white justify-center bg-sunday_primary h-24 w-full">Privacy Policy</h1>
      
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-sunday_tertiary">Introduction</h2>
        <p>
          Welcome to Eventful Sunday, owned and operated by CubeQ Analytica, providing a comprehensive event discovery and management platform across the UK. By using our services—whether on our website, mobile applications, or associated tools—you agree to abide by these Terms and Conditions. Please read them carefully to understand your rights and obligations. If you do not agree, discontinue your use of our services immediately.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-sunday_tertiary">Scope of Services</h2>
        <p className="mb-2">Eventful Sunday allows users to discover, promote, manage, and attend various events, from concerts to conferences. We offer:</p>
        <ul className="list-disc ml-8">
          <li className="mb-2"><strong>Event Discovery:</strong> Browse events by category, location, or interest.</li>
          <li className="mb-2"><strong>Organizer Tools:</strong> Event creation, customization, attendee management, and analytics.</li>
          <li className="mb-2"><strong>Secure Ticketing:</strong> Simplified, secure ticket sales through our platform.</li>
          <li className="mb-2"><strong>Community Support:</strong> Promoting diversity and inclusivity for events throughout the UK.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-sunday_tertiary">User Obligations</h2>
        <p className="mb-2">By using Eventful Sunday, you agree to the following:</p>
        <ul className="list-disc ml-8">
          <li className="mb-2"><strong>Account Responsibility:</strong> You are responsible for maintaining the confidentiality of your account details, including your username and password, and for any actions taken through your account.</li>
          <li className="mb-2"><strong>Event Organizers:</strong> Must ensure all event information (date, time, venue, etc.) is accurate and up-to-date. Organizers are responsible for managing ticketing, cancellations, and refunds according to both these Terms and applicable laws.</li>
          <li className="mb-2"><strong>Attendees:</strong> You agree to adhere to the Organizer’s terms for each event. Eventful Sunday is not liable for any issues related to event quality, cancellations, or other unforeseen changes; these should be addressed directly with the Organizer.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-sunday_tertiary">Ticketing and Refund Policy</h2>
        <p>
          All tickets purchased through Eventful Sunday are subject to the specific terms set by the Organizer. If you need to cancel, please refer to the event’s refund policy, as some events may not offer refunds. Eventful Sunday acts as an intermediary and is not responsible for third-party issues. In cases of disputes regarding refunds or event alterations, attendees should contact the Organizer directly.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-sunday_tertiary">Privacy and Data Usage</h2>
        <p>
          By using our platform, you consent to our data collection practices outlined in our Privacy Policy. This includes the collection, storage, and use of your data to improve your experience on our platform. Your data will not be sold or shared with third parties except as necessary to facilitate service or as required by law.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-sunday_tertiary">Intellectual Property</h2>
        <p>
          Eventful Sunday and all related content (logos, designs, software, and trademarks) are the property of CubeQ Analytica. Users are granted a limited, non-exclusive right to use our platform solely for personal use. Unauthorized reproduction, distribution, or modification of our intellectual property is prohibited.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-sunday_tertiary">Limitation of Liability</h2>
        <p>
          CubeQ Analytica is not liable for any direct, indirect, incidental, or consequential damages that may result from the use or inability to use our platform, including any issues related to event cancellations or changes made by the Organizer. Our liability is limited to the fullest extent permitted by law and is restricted to the amount paid by the User for the specific event or service.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-sunday_tertiary">Amendments to Terms</h2>
        <p>
          CubeQ Analytica reserves the right to modify these Terms at any time. Any significant changes will be posted on our website or notified via email. By continuing to use Eventful Sunday after such changes, you accept the updated Terms.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-sunday_tertiary">Governing Law and Jurisdiction</h2>
        <p>
          These Terms are governed by the laws of England and Wales. In the event of any legal dispute, you agree to submit to the jurisdiction of the courts of England and Wales.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-4 text-sunday_tertiary">Contact Information</h2>
        <p className="mb-2">
          For questions regarding these Terms, contact us at:
        </p>
        <p className="mb-2">Email: <a href="mailto:support@eventfullsunday.co.uk" className="text-blue-600">support@eventfullsunday.co.uk</a></p>
        <p>Address: Republic Export Building, The Dock – 3rd Floor, 1 Clove Crescent, East India, London, E14 2BA, United Kingdom</p>
      </section>

      
      </div>
    </div>
  );
};

export default PrivacyPolicy;
