// src/page/PurchasedTicketModal.jsx

import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import PropTypes from 'prop-types';

/**
 * PurchasedTicketModal Component
 *
 * Displays detailed information about a purchased ticket along with a QR code.
 *
 * @param {Object} ticket - The user ticket object containing all necessary details.
 * @param {boolean} isOpen - Flag to determine if the modal should be displayed.
 * @param {Function} onClose - Function to handle closing the modal.
 */
const PurchasedTicketModal = ({ ticket, isOpen, onClose }) => {
  if (!isOpen || !ticket) return null;

  console.log("ticket: ", ticket);

  // Create data for QR code
  const qrData = JSON.stringify({
    reference_id: ticket.reference_id,
    event_id: ticket.event,
    event_name: ticket.event_name,

    venue: ticket.venue_name,
    address: ticket.venue_address ? ticket.venue_address : "N/A",

    start_time: ticket.start_time,
    end_time: ticket.end_time,

    ticket_quantity: ticket.ticket_quantity,
    ticket_category: ticket.ticket_category.charAt(0).toUpperCase() + ticket.ticket_category.slice(1),

    sub_total: ticket.sub_total,
    total: ticket.total,

    // date_purchased: new Date(ticket.date_purchased).toLocaleString('en-GB', {
    //   day: '2-digit',
    //   month: '2-digit',
    //   year: 'numeric',
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   second: '2-digit',
    //   hour12: false
    // }),
  });

  return (
    <div
      className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative z-50 overflow-y-auto max-h-full"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
      >
        <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">
          Your Ticket Details for {ticket.event_name}
        </h2>

        <div className="mb-4">
          <p><strong>Reference ID:</strong> {ticket.reference_id || "N/A"}</p>
          <p><strong>Event:</strong> {ticket.event_name}</p>
          <p><strong>Venue:</strong> {ticket.venue_name || "N/A"}</p>
          <p><strong>Address:</strong> {ticket.venue_address || "N/A"}</p>

          {/* TO BE ADDED LATER */}
          {/* <p><strong>Date Purchased:</strong> {new Date(ticket.date_purchased).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
          })}</p> */}

          <p><strong>Start Time:</strong> {ticket.start_time || "N/A"}</p>
          <p><strong>End Time:</strong> {ticket.end_time || "N/A"}</p>
          <p><strong>Ticket Quantity:</strong> {ticket.ticket_quantity || 1}</p>
          <p><strong>Ticket Category:</strong> {ticket.ticket_category.charAt(0).toUpperCase() + ticket.ticket_category.slice(1)}</p>
          <p><strong>Price:</strong> {ticket.price ? `£${ticket.price}` : "Free"}</p>
          <p><strong>Sub Total:</strong> {ticket.sub_total ? `£${ticket.sub_total}` : "Free"}</p>
          <p><strong>Total:</strong> {ticket.total ? `£${ticket.total}` : "Free"}</p>
          {/* Add any additional fields as necessary */}
        </div>

        <h2 className="text-lg font-semibold text-gray-800 text-center">
          Your QR code for {ticket.event_name}
        </h2>

        <div className="flex justify-center mb-4">
          {/* Commenting out old QR Code library */}
          {/* <QRCodeCanvas
            value={qrData}
            size={256}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"H"}
            includeMargin={true}
          /> */}

          {/* Testing the QR Code from backend */}
          <img src={ticket.qr_code} alt="QR Code"/>

        </div>

        <button
          onClick={onClose}
          className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-auto rounded-lg transition duration-300 ease-in-out w-[150px]"
        >
          Close
        </button>
      </div>
    </div>
  );
};

// Prop type validation
PurchasedTicketModal.propTypes = {
  ticket: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PurchasedTicketModal;
