import { toast } from "react-toastify";
import apiClient from "../../../apiClient/apiClient";
import {
  SUBSCRIPTION_REQUEST,
  SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_FAILURE,
} from "../constant/SubscriptionConstants";
export const subscription = (values) => async (dispatch) => {
  dispatch({ type: SUBSCRIPTION_REQUEST });
  try {
    const response = await apiClient.get("subscription/");
    const { data } = response;
    if(data) {
        console.log(data);
        const existing = data.filter((item) => values.email === item.email);
        console.log(existing);
        if(existing.length === 0) {
            const resp = await apiClient.post("subscription/", values);
            dispatch({
                type: SUBSCRIPTION_SUCCESS,
                payload: resp.data,
              });
              toast.success('Newsletter subscribed successfully');
        } else {
            dispatch({
                type: SUBSCRIPTION_FAILURE,
                payload: "You are already subscribed to the newsletter!!!",
              });
              toast.info('You are already subscribed to the newsletter!!!');
        }
    } else {
        console.log("[LOGIN DEBUG] Else Response: ", response);
        throw new Error("Invalid response from the server");
    }
  } catch (error) {
    dispatch({
      type: SUBSCRIPTION_FAILURE,
      payload:
        error.response?.data?.error ||
        "Subscription failed",
    });
    toast.error(error.response?.data?.error ||
        "Subscription failed");
  }
};