// src/redux/auth/reducer/LoginReducer.js

import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT, REFRESH_FAILURE, REFRESH_SUCCESS } from "../constant/LoginConstant";

const initialState = {
  isAuthenticated: false,
  access: (localStorage.getItem("access") || sessionStorage.getItem("access")) || null,
  refresh: (localStorage.getItem('refresh') || sessionStorage.getItem("refresh")) || null,
  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        access: action.payload.access,
        refresh: action.payload.refresh,
        error: null,
      };
    case LOGIN_FAILURE:
      return { ...state, error: action.payload };
    case LOGOUT:
      return {
        ...state,
        access: null,
        refresh: null,
        isAuthenticated: false,
        error: null,
      };
    case REFRESH_SUCCESS:
      return { ...state, access: action.payload };
    case REFRESH_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default userReducer;
