// src/components/pages/Home.jsx

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer";
import CardSlider from "../../component/Events/CardSlider";
import { fetchGetItems } from "../../redux/createEvent/createEventAction/GetEventAction";
import { eventTypeChoices } from "../../assets/static/eventTypes";
import HomeHelmet from "../../component/MetaHelmet/HomeHelmet";
import MiddleBanner from "./BannerComponents/MiddleBanner";
import SlickBanner from "./BannerComponents/SlickBanner";
import { getFilteredEvents } from "../../redux/auth/action/filterSelector";

const Home = () => {
  const dispatch = useDispatch();

  let renderedCount = 0;

  const today = new Date().setHours(0, 0, 0, 0);

  // Access the events data from Redux store
  const events = useSelector((state) => state.getEvent);
  const { data, loading: eventsLoading, error } = events;

  // Ensure 'data' is always an array
  const allEvents = Array.isArray(data) ? data : [];

  // Get filters from Redux store
  const filters = useSelector((state) => state.filters);

  const filtersApplied = Object.values(filters).some(
    (value) => value !== "" && value !== false
  );

  // Get filtered events using selector
  const filteredData = useSelector(getFilteredEvents)?.filter(
    (event) => new Date(event.end_date).setHours(0, 0, 0, 0) >= today       //remove previous events
  );

  const itemsAreEqual = (searched, allEvents) => {
    if (searched.length !== allEvents.length) return false;
  
    return searched.every((item1) =>
      allEvents.some((item2) => item1.event_id === item2.event_id)
    );
  };

  // Fetch all events when the component mounts
  useEffect(() => {
    dispatch(fetchGetItems());
  }, [dispatch]);

  // Filter out expired events
  const activeEvents = allEvents.filter(
    (event) => new Date(event.end_date).setHours(0, 0, 0, 0) >= today
  );

  // Calculate the date three days ago from today
  const threeDaysAgo = new Date();
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

  // Filter events created in the last 3 days and are not expired
  const newListings = activeEvents.filter((event) => {
    const eventDate = new Date(event.created_at);
    return eventDate >= threeDaysAgo;
  });

  // Calculate total events in event types
  const totalEventsInTypes = eventTypeChoices.reduce((sum, type) => {
    const eventsOfType = activeEvents.filter(
      (event) => event.event_segment === type.key
    );
    return sum + eventsOfType.length;
  }, 0);

  // Check if there are no new listings
  const noNewListings = newListings.length === 0;

  // Determine if there are no events to show
  const noEventsToShow =
    totalEventsInTypes === 0 && noNewListings && !eventsLoading;

  return (
    <div className="min-h-screen flex flex-col font-poppins bg-[#f2f4f7]">
      <HomeHelmet />
      {/* Navbar with filtering capabilities is now accessible on all pages */}
      <Navbar />

      {/* Banner */}
      <SlickBanner events={activeEvents} />

      {/* Main Content */}
      <main className="flex-grow flex items-center justify-center">
        {/* Container for max-width and centered alignment */}
        <div className="flex flex-col flex-grow max-w-[1200px] mx-auto px-4">
          {filtersApplied && !itemsAreEqual(  filteredData, activeEvents ) ? (
            <>
              <p className="font-poppins text-lg font-semibold mt-7">
                SEARCH RESULTS
              </p>
              {filteredData.length > 0 ? (
                <CardSlider data={filteredData} />
              ) : (
                <p className="mt-10 mb-20 text-3xl text-gray-500 opacity-60 text-center">
                  <i>No events match your filters.</i>
                </p>
              )}
            </>
          ) : (
            <>
              {/* New Listings Section */}
              {newListings.length > 0 && (
                <div>
                  <p
                    className="font-poppins text-lg font-semibold mt-7 uppercase cursor-pointer inline-block"
                    title="Events added in the last 3 days"
                  >
                    New Listings
                  </p>
                  <CardSlider data={newListings} />
                </div>
              )}

              {noEventsToShow ? (
                <div className="flex flex-col flex-grow items-center justify-center">
                  <p className="text-center text-gray-500 text-3xl">
                    No events available at the moment. Please check back later.
                  </p>
                </div>
              ) : (
                // Dynamic Event Type Sections
                eventTypeChoices.map((type) => {
                  const eventsOfType = activeEvents.filter(
                    (event) => event.event_segment === type.key
                  );
                  if (eventsOfType.length === 0) return null;

                  renderedCount++;

                  return (
                    <div key={type.key}>
                      <p className="font-poppins text-lg font-semibold mt-7 uppercase">
                        {type.label}
                      </p>
                      <CardSlider
                        className="relative"
                        data={eventsOfType}
                        loading={eventsLoading}
                      />

                      {/* Add the component after rendering half of the non-empty sections */}
                      {renderedCount ===
                        Math.ceil(
                          eventTypeChoices.filter((type) =>
                            activeEvents.some(
                              (event) => event.event_segment === type.key
                            )
                          ).length / 2
                        ) && <MiddleBanner />}
                    </div>
                  );
                })
              )}
            </>
          )}
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
