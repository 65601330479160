// src/redux/selectors/filteredEventsSelector.js

import { createSelector } from 'reselect';

const getEvents = (state) => state.getEvent.data;
const getFilters = (state) => state.filters;

export const getFilteredEvents = createSelector(
    [getEvents, getFilters],
    (events, filters) => {
        if (!events) return [];
        let filtered = events;

        const {
            name,
            category,
            dateRange,
            startTime,
            endTime,
            location,
            ticketPriceMin,
            ticketPriceMax,
            isFreeTicket,
            capacityMin,
            capacityMax,
        } = filters;

        // Filter by name
        if (name) {
            filtered = filtered.filter((event) =>
                event.event_name && event.event_name.toLowerCase().includes(name.toLowerCase())
            );
        }

        // Filter by category (category_name)
        if (category) {
            filtered = filtered.filter(
                (event) => event.category_name === category
            );
        }

        // Filter by date range
        if (dateRange.start && dateRange.end) {
            const start = new Date(dateRange.start);
            const end = new Date(dateRange.end);
            filtered = filtered.filter((event) => {
                const eventDate = new Date(event.start_date);
                return eventDate >= start && eventDate <= end;
            });
        } else if (dateRange.start) {
            const start = new Date(dateRange.start);
            filtered = filtered.filter((event) => {
                const eventDate = new Date(event.start_date);
                return eventDate >= start;
            });
        } else if (dateRange.end) {
            const end = new Date(dateRange.end);
            filtered = filtered.filter((event) => {
                const eventDate = new Date(event.start_date);
                return eventDate <= end;
            });
        }

        // Filter by startTime
        if (startTime) {
            const startTimeParts = startTime.split(':');
            const startHour = parseInt(startTimeParts[0], 10);
            const startMinute = parseInt(startTimeParts[1], 10);
            filtered = filtered.filter((event) => {
                const [eventHour, eventMinute] = event.start_time.split(':').map(Number);
                return (
                    eventHour > startHour ||
                    (eventHour === startHour && eventMinute >= startMinute)
                );
            });
        }

        // Filter by endTime
        if (endTime) {
            const endTimeParts = endTime.split(':');
            const endHour = parseInt(endTimeParts[0], 10);
            const endMinute = parseInt(endTimeParts[1], 10);
            filtered = filtered.filter((event) => {
                const [eventHour, eventMinute] = event.end_time.split(':').map(Number);
                return (
                    eventHour < endHour ||
                    (eventHour === endHour && eventMinute <= endMinute)
                );
            });
        }

        // Filter by location
        if (location) {
            const loc = location.toLowerCase();
            filtered = filtered.filter(
                (event) =>
                    (event.venue_name &&
                        event.venue_name.toLowerCase().includes(loc)) ||
                    (event.venue_address &&
                        event.venue_address.toLowerCase().includes(loc)) ||
                    (event.venue_post_code &&
                        event.venue_post_code.toLowerCase().includes(loc)) ||
                    (event.event_origin_country &&
                        event.event_origin_country.toLowerCase().includes(loc))
            );
        }

        // Filter by ticket price
        if (isFreeTicket) {
            filtered = filtered.filter(
                (event) =>
                    event.ticket_price === "0.00" ||
                    event.ticket_price === 0 ||
                    event.ticket_price === null
            );
        } else {
            if (ticketPriceMin) {
                filtered = filtered.filter(
                    (event) =>
                        event.ticket_price !== null &&
                        parseFloat(event.ticket_price) >= parseFloat(ticketPriceMin)
                );
            }
            if (ticketPriceMax) {
                filtered = filtered.filter(
                    (event) =>
                        event.ticket_price !== null &&
                        parseFloat(event.ticket_price) <= parseFloat(ticketPriceMax)
                );
            }
        }

        // Filter by capacity
        if (capacityMin) {
            filtered = filtered.filter(
                (event) =>
                    event.capacity !== null &&
                    parseInt(event.capacity) >= parseInt(capacityMin)
            );
        }
        if (capacityMax) {
            filtered = filtered.filter(
                (event) =>
                    event.capacity !== null &&
                    parseInt(event.capacity) <= parseInt(capacityMax)
            );
        }

        return filtered;
    }
);
