import apiClient from "../../../apiClient/apiClient";
import { toast } from "react-toastify";

export const registerRequest = () => ({
  type: 'REGISTER_REQUEST',
});

export const registerSuccess = (user) => ({
  type: 'REGISTER_SUCCESS',
  payload: user,
});

export const registerFailure = (error) => ({
  type: 'REGISTER_FAILURE',
  payload: error,
});

// for register for user 
export const registerUser = (values) => async (dispatch) => {
  dispatch(registerRequest());

  try {
    if (localStorage.getItem("access")) {
      localStorage.removeItem("access");
    }

    if (sessionStorage.getItem("access")) {
      sessionStorage.removeItem("access");
    }

    const response = await apiClient.post('register/', values);
    const user = response.data;

    dispatch(registerSuccess(user));

    window.location.href = `/emailConfirmation?email=${encodeURIComponent(user.email)}`;

    // Optional toast message instead of redirection

    // if (!toast.isActive(1)) {
    //   console.log("Displaying registration success toast (Toast ID: 1)");
    //   toast.success(
    //     "You've successfully registered and will be redirected shortly. Please check your inbox and click the link to verify your account.",
    //     { toastId: 1, containerId: "register" }
    //   );
    // }

    setTimeout(() => {
      window.location.href = "/login";
    }, 5000)

  } catch (error) {
    console.error("Registration error:", error);

    if (error.response && error.response.data) {
      const { data } = error.response;

      if (data.full_name && !toast.isActive(2)) {
        console.log("Displaying full_name error toast (Toast ID: 2)");
        toast.error(data.full_name.join(", "), { toastId: 2, containerId: "register" });
      }
      
      if (data.password && !toast.isActive(4)) {
        console.log("Displaying password error toast (Toast ID: 4)");
        toast.error(data.password.join(", "), { toastId: 4, containerId: "register" });
      }
      if (data.email) {
        if (Array.isArray(data.email) && !toast.isActive(5)) {
          console.log("Displaying email array error toast (Toast ID: 5)");
          toast.error(data.email.join(", "), { toastId: 5, containerId: "register" });
        } else if (data.email === "email already exists" && !toast.isActive(6)) {
          console.log("Displaying email already exists error toast (Toast ID: 6)");
          toast.error(
            "Email already exists. Please use a different email, or reset your password.",
            { toastId: 6, containerId: "register" }
          );
        }
      }
      if (data.phone_number) {
        if (Array.isArray(data.phone_number) && !toast.isActive(5)) {
          console.log("Displaying phone_number array error toast (Toast ID: 5)");
          toast.error(data.phone_number.join(", "), { toastId: 5, containerId: "register" });
        } else if (data.phone_number === "phone number already exists" && !toast.isActive(9)) {
          console.log("Displaying phone number already exists error toast (Toast ID: 9)");
          toast.error(
            "Phone number already registered. Please use another number.",
            { toastId: 9, containerId: "register" }
          );
        }
      }

      if (
        !data.full_name &&
        !data.password &&
        !data.email &&
        !data.phone_number &&
        !toast.isActive(11)
      ) {
        console.log("Displaying generic error toast (Toast ID: 11)");
        toast.error("An error occurred. Please try again later.", { toastId: 11, containerId: "register" });
      }
    } else {
      if (!toast.isActive(12)) {
        console.log("Displaying fallback error toast (Toast ID: 12)");
        toast.error("An error occurred. Please try again later.", { toastId: 12, containerId: "register" });
      }
    }

    dispatch(registerFailure("Error registering user"));
  }
};
