// src/App.js

import { useEffect, useState, useCallback } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./auth/Login/Login";
import Register from "./auth/Register/Register";
import ForgotPassword from "./auth/Password/ForgotPassword";
import ResetPassword from "./auth/Password/ResetPassword";
import ForgotPasswordConfirm from "./auth/Password/ForgotPasswordConfirm.jsx";
import EmailConfirmation from "./auth/Register/EmailConfirmation.jsx";
import SuccessfulVerification from "./auth/Login/SuccessfulVerification.jsx";
import Home from "./page/Home/Home.jsx";
import EventDetails from "./page/EventDetail/EventDetails";
import Footer from "./page/Footer";
import Dashboard from "./page/Dashboard/dashboard.jsx";
import ReadyforTickets from "./component/ticketComponent/step0-readyfortickets";
import PurchaseHistory from "./page/Dashboard/PurchaseDetails/PurchaseHistory.jsx";
import ReactGA from "react-ga4";
import CookieBanner from "./CookieBanner";
import AccessAuth from "./ProtectedRoutes/AccessAuth";

function App() {
  const location = useLocation();
  const [gaInitialized, setGaInitialized] = useState(false);

  const handleCookieAccept = useCallback(() => {
    if (!gaInitialized) {
      // Initialize GA
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, { debug: true });
      setGaInitialized(true);

      // Log initialization
      console.log(`GA Initialized`);
    }
  }, [gaInitialized]);

  useEffect(() => {
    if (localStorage.getItem("cookie-consent") === "accepted" && !gaInitialized) {
      handleCookieAccept();
    }
  }, [gaInitialized, handleCookieAccept]);

  useEffect(() => {
    if (gaInitialized) {
      // Send pageview on GA initialization and location change
      ReactGA.send({ hitType: "pageview", page: location.pathname });
      console.log(`GA Pageview sent for ${location.pathname}`);
    }
  }, [gaInitialized, location]);

  return (
    <>
      <CookieBanner onAccept={handleCookieAccept} />
      <Routes>
        <Route exact path="/login" element={
          <AccessAuth>
            <Login />
          </AccessAuth>
        } />
        <Route exact path="/register" element={
          <AccessAuth>
            <Register />
          </AccessAuth>
        } />
        <Route exact path="/forgotPassword" element={<ForgotPassword />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/passwordResetConfirmation" element={<ForgotPasswordConfirm />} />
        <Route exact path="/emailConfirmation" element={<EmailConfirmation />} />
        <Route exact path="/activate/:uid/:token" element={<SuccessfulVerification />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/footer" element={<Footer />} />
        <Route exact path="/event/:id" element={<EventDetails />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/purchase-history" element={<PurchaseHistory />} />
        <Route exact path="/readyfortickets" element={<ReadyforTickets />} />
      </Routes>
    </>
  );
}

export default App;
