import {
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_UPDATE_REQUEST,
  GET_PROFILE_UPDATE_SUCCESS,
  GET_PROFILE_UPDATE_FAILURE,
  GET_PROFILE_VERIFY_RESEND_REQUEST,
  GET_PROFILE_VERIFY_RESEND_SUCCESS,
  GET_PROFILE_VERIFY_RESEND_FAILURE
} from "../constant/ProfileConstant";

const initialState = {
  loading: false,
  profile: null,
  error: null,
  updating: false,  // separate loading state for profile updates
  updateError: null // separate error state for profile updates
};

const initialStateVerif = {
  loading: false,
  data: null,
  error: null
}

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload,
      };

    case GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: null,
      };

    case GET_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        profile: action.payload,
      };

    case GET_PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        updateError: action.payload,
      };


    default:
      return state;
  }
};

// Verification email resend cases
export const sendResetVerificationEmailReducer = (state = initialStateVerif, action) => {
  switch (action.type) {
    case GET_PROFILE_VERIFY_RESEND_REQUEST:
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    case GET_PROFILE_VERIFY_RESEND_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_PROFILE_VERIFY_RESEND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state
  }
}
