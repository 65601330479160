
import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import persistedReducer from "./rootReducer"; // Import the persisted reducer

// Import redux-persist utilities
import { persistStore } from "redux-persist";

// Create the Redux store with the persisted reducer and middleware
const store = createStore(
  persistedReducer,
  applyMiddleware(thunk) 
);

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };
