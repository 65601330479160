import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfile, updateUserProfile, sendResetVerificationEmail } from "../../../redux/auth/action/userProfileActions";
import { useNavigate } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import { VscUnverified, VscVerified } from "react-icons/vsc";
import { FaUser, FaInfoCircle, FaLock } from "react-icons/fa";
import userImage from "../../../assets/images/user.png";
import ProfileUpdateModal from "./ProfileUpdateModal";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profile = () => {
  const dispatch = useDispatch();
  const profileState = useSelector((state) => state.profile);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const resetVerificationState = useSelector((state) => state.resetVerification);
  const [hasDisplayedToast, setHasDisplayedToast] = useState(true);
  const navigate = useNavigate();

  // Fetch profile data
  useEffect(() => {
    dispatch(getUserProfile());

    let timer;
    if (isCooldown) {
      timer = setTimeout(() => {
        setIsCooldown(false);
      }, 10000); // 10 seconds cooldown
    }
    return () => clearTimeout(timer); // Cleanup on unmount
  }, [dispatch, isCooldown]);

  // Handle verification email toast
  useEffect(() => {
    if (
      resetVerificationState.data &&
      !resetVerificationState.loading &&
      !hasDisplayedToast
    ) {
      console.log(resetVerificationState.data);

      if (!toast.isActive(1, "profileComponent")) {
        toast.success(
          "You've successfully requested an account verification email!", {
          toastId: 1
        }
        );
      }

      setHasDisplayedToast(true);
    }
    if (
      resetVerificationState.error &&
      !hasDisplayedToast
    ) {
      if (!toast.isActive(2, "profileComponent")) {
        toast.error(
          "There was a problem with sending a verification email. Please try again later.", {
          toastId: 2
        }
        );
      }
      setHasDisplayedToast(true);
    }
  }, [
    resetVerificationState.data,
    resetVerificationState.error,
    resetVerificationState.loading,
    hasDisplayedToast,
  ]);

  if (!localStorage.getItem("access") && !sessionStorage.getItem("access")) {
    navigate("/login"); // Navigate to login page if there's no logged in profile
  }

  if (!profileState.profile) {
    return <div>Loading...</div>;
  }

  const {
    full_name,
    email,
    phone_number,
    profile_picture,
    is_verified
  } = profileState.profile || {};

  const initialValues = {
    full_name,
    email,
    phone_number: phone_number ?? "",
    password: "",
    confirm_password: "",
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Handle image file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {

      // Create FormData object to hold the profile data
      const formData = new FormData();
      formData.append('full_name', full_name);
      formData.append('email', email);
      formData.append('phone_number', phone_number);
      formData.append('profile_picture', file);

      console.log(formData);

      dispatch(updateUserProfile(formData));
    }
  };

  // Function to resend verification email
  const handleResendVerificationEmail = () => {
    dispatch(sendResetVerificationEmail(email));
    setHasDisplayedToast(false);
    setIsCooldown(true);
  };

  const getVerifiedStatus = () => {
    if (profileState.loading) {
      return (
        <span className="text-gray-500">Loading...</span>
      );
    } else {
      if (is_verified && is_verified === true) {
        console.log("verified!!");

        return (
          <span className="flex">
            <span className="text-sunday_success">Verified</span> <VscVerified size={20} className="ms-1 my-[2px] text-sunday_success" />
          </span>
        );
      } else if (is_verified === false) {
        console.log("not verified!!");

        return (
          <>
            <button
              className="flex items-center"
              onClick={handleResendVerificationEmail}
              disabled={isCooldown}
              title="Click to resend a verification link to your email"
            >
              <span
                className={`flex items-center mx-auto underline transition duration-200 ${isCooldown
                  ? "text-gray-500 no-underline opacity-80"
                  : "text-sunday_error hover:brightness-110"
                  }`}
              >
                <span>Unverified</span>
                <VscUnverified size={20} className="ms-1 my-[2px]" />
              </span>
            </button>
            {isCooldown && (
              <span className="text-red-500 text-xs mt-1">
                You can request a new email in 10 seconds.
              </span>
            )}
          </>
        );
      }
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <ToastContainer position="top-right" containerId="profileComponent" autoClose={3000} />

      {!profileState.profile ? (
        <div>Loading...</div>
      ) : (
        <>
          {/* Profile Header with Background */}
          <div className="bg-gradient-to-r from-sunday_primary to-sunday_secondary h-48 rounded-t-lg relative">
            <div className="absolute -bottom-16 left-8 flex items-end space-x-6">
              <div className="relative">
                <img
                  src={profile_picture || userImage}
                  alt="Profile"
                  className="w-32 h-32 rounded-full border-4 border-white shadow-lg object-cover"
                />
              </div>
            </div>
          </div>

          {/* Profile Content */}
          <div className="bg-white rounded-b-lg shadow-sm">
            {/* Profile Header Info */}
            <div className="pt-20 px-8 pb-8 border-b border-gray-200">
              <div className="flex flex-col sm:flex-row justify-between items-start gap-4">
                <div>
                  <div className="flex items-center gap-3 flex-wrap">
                    <h1 className="text-2xl font-bold text-gray-900">{full_name}</h1>
                    {getVerifiedStatus()}
                  </div>
                  <p className="text-gray-600 mt-1">{email}</p>
                </div>
                <div className="flex gap-3 w-full sm:w-auto flex-col lg:flex-row">
                  <button
                    onClick={() => setIsModalOpen(true)}
                    className="flex-1 sm:flex-none inline-flex items-center justify-center px-4 py-2 bg-sunday_primary text-white text-sm font-medium rounded-md hover:bg-sunday_secondary transition-all duration-200 shadow-sm"
                  >
                    <FiEdit2 className="mr-2" />
                    Update Profile
                  </button>
                  <button
                    onClick={() => navigate("/forgotpassword")}
                    className="flex-1 sm:flex-none inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-gray-700 text-sm font-medium rounded-md hover:bg-gray-50 transition-all duration-200"
                  >
                    <FaLock className="mr-2" />
                    Change Password
                  </button>
                </div>
              </div>
            </div>

            {/* Profile Stats */}
            <div className="grid grid-cols-3 border-b border-gray-200">
              <div className="px-8 py-4 text-center border-r border-gray-200">
                <p className="text-2xl font-semibold text-sunday_primary">0</p>
                <p className="text-sm text-gray-600">Events Attended</p>
              </div>
              <div className="px-8 py-4 text-center border-r border-gray-200">
                <p className="text-2xl font-semibold text-sunday_primary">0</p>
                <p className="text-sm text-gray-600">Upcoming Events</p>
              </div>
              <div className="px-8 py-4 text-center">
                <p className="text-2xl font-semibold text-sunday_primary">0</p>
                <p className="text-sm text-gray-600">Total Tickets</p>
              </div>
            </div>

            {/* Profile Details Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-8">
              {/* Personal Information */}
              <div className="bg-gray-50 rounded-lg p-6">
                <h2 className="flex items-center text-lg font-semibold text-gray-900 mb-4">
                  <FaUser className="mr-2 text-sunday_primary" />
                  Personal Information
                </h2>
                <div className="space-y-3">
                  <div>
                    <label className="text-xs text-gray-500 uppercase">Full Name</label>
                    <p className="text-gray-900">{full_name}</p>
                  </div>
                  <div>
                    <label className="text-xs text-gray-500 uppercase">Email</label>
                    <p className="text-gray-900">{email}</p>
                  </div>
                  <div>
                    <label className="text-xs text-gray-500 uppercase">Phone</label>
                    <p className="text-gray-900">{phone_number || 'Not provided'}</p>
                  </div>
                </div>
              </div>

              {/* Account Status */}
              <div className="bg-gray-50 rounded-lg p-6">
                <h2 className="flex items-center text-lg font-semibold text-gray-900 mb-4">
                  <FaInfoCircle className="mr-2 text-sunday_primary" />
                  Account Status
                </h2>
                <div className="space-y-3">
                  <div>
                    <label className="text-xs text-gray-500 uppercase">Verification Status</label>
                    <div className="mt-1">{getVerifiedStatus()}</div>
                  </div>
                  <div>
                    <label className="text-xs text-gray-500 uppercase">Member Since</label>
                    <p className="text-gray-900">2024</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Profile Update Modal */}
          <ProfileUpdateModal
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            initialValues={initialValues}
          />
        </>
      )}
    </div>
  );
};

export default Profile;