import React from 'react'
import { motion } from "framer-motion";

function MenuNav({ isMenuOpen, toggleMenu }) {
    // Menu icon variants for animation
    const menuIconVariants = {
        closed: { rotate: 0 },
        open: { rotate: 45, y: 5 },
    };

    const menuIconMiddleVariants = {
        closed: { opacity: 1 },
        open: { opacity: 0 },
    };

    const menuIconBottomVariants = {
        closed: { rotate: 0 },
        open: { rotate: -45, y: -7 },
    };

  return (
        <button
            onClick={toggleMenu}
            className="flex flex-col items-center justify-center w-8 h-8 md:hidden focus:outline-none"
        >
            <motion.span
                className="block w-6 h-[2px] bg-black"
                animate={isMenuOpen ? "open" : "closed"}
                variants={menuIconVariants}
                transition={{ duration: 0.3 }}
            />
            <motion.span
                className="block w-6 h-[2px] bg-black my-1"
                animate={isMenuOpen ? "open" : "closed"}
                variants={menuIconMiddleVariants}
                transition={{ duration: 0.3 }}
            />
            <motion.span
                className="block w-6 h-[2px] bg-black"
                animate={isMenuOpen ? "open" : "closed"}
                variants={menuIconBottomVariants}
                transition={{ duration: 0.3 }}
            />
        </button>
  )
}

export default MenuNav
