import React from 'react'
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function AccessAuth({ children }) {
    const token = localStorage.getItem("access") || sessionStorage.getItem("access");

    console.log(token);

    if (token) {
        toast.error("You are already logged in");
        return <Navigate to="/" replace />;
    }
  return children;
}

export default AccessAuth
