export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';
export const GET_PROFILE_UPDATE_REQUEST = 'GET_PROFILE_UPDATE_REQUEST';
export const GET_PROFILE_UPDATE_SUCCESS = 'GET_PROFILE_UPDATE_SUCCESS';
export const GET_PROFILE_UPDATE_FAILURE = 'GET_PROFILE_UPDATE_FAILURE';
export const GET_PROFILE_VERIFY_RESEND_REQUEST = 'GET_PROFILE_VERIFY_RESEND_REQUEST';
export const GET_PROFILE_VERIFY_RESEND_SUCCESS = 'GET_PROFILE_VERIFY_RESEND_SUCCESS';
export const GET_PROFILE_VERIFY_RESEND_FAILURE = 'GET_PROFILE_VERIFY_RESEND_FAILURE';

