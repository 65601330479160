import React from 'react'
import Modal from 'react-modal';
import PropTypes from 'prop-types';

// Bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

function LogoutConfirmationModal({ isOpen, onRequestClose, onConfirm}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Confirm Logout"
      className="fixed inset-0 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
    >
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-semibold mb-4">Confirm Logout</h2>
        <p className="mb-6">
          Are you sure you want to logout?
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onRequestClose}
            className="w-40 p-2 bg-white border border-sunday_tertiary text-sunday_tertiary hover:brightness-95 transition duration-200 rounded-full"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="w-40 p-2 bg-sunday_primary text-white rounded-full hover:brightness-95 transition duration-200"
          >
            Logout
          </button>
        </div>
      </div>
    </Modal>
  )
}

LogoutConfirmationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  };

export default LogoutConfirmationModal;
