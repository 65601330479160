import {
    PASSWORD_UPDATE_REQUEST,
    PASSWORD_UPDATE_SUCCESS,
    PASSWORD_UPDATE_FAILURE
} from '../constant/ResetConstant';

const passwordResetReducer = (state = {}, action) => {
    switch (action.type) {
        case PASSWORD_UPDATE_REQUEST:
            return { loading: true };
        case PASSWORD_UPDATE_SUCCESS:
            return { loading: false, success: true };
        case PASSWORD_UPDATE_FAILURE:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export default passwordResetReducer;