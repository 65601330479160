// src/components/pages/readyfortickets/step1-TicketSelection.jsx

import React, { useEffect } from "react";
import { CiLocationOn, CiClock2 } from "react-icons/ci";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedQuantity } from "../../redux/auth/action/selectedTicketActions";
import { EventGetItems } from "../../redux/auth/action/eventDetailAction";
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom"; // Import useParams
import { sanitizeDescription } from "../../page/EventDetail/sanitizeDescription";

const TicketSelection = ({
  handleGetTickets, // Keep as a prop
}) => {
  const { id } = useParams(); // Extract event ID from URL
  const dispatch = useDispatch();

  // Access Redux store's selectedTicket and ticketQuantity
  const selectedTicket = useSelector((state) => state.selectedTicket.selectedTicket);
  const ticketQuantity = useSelector((state) => state.selectedTicket.ticketQuantity);

  // Access eventData from Redux
  const eventData = useSelector((state) => state.eventItems.data);
  const loading = useSelector((state) => state.eventItems.loading);
  const error = useSelector((state) => state.eventItems.error);

  // Fetch eventData if not present
  useEffect(() => {
    if (!eventData && id) {
      dispatch(EventGetItems(id));
    }
  }, [dispatch, eventData, id]);

  const ticketPrice = parseFloat(selectedTicket?.price || 0);
  const defaultImage = "https://via.placeholder.com/300x200.png?text=Default+Image";

  const timeFormat = (time) => {
    if (time) {
      return time.split(':').slice(0, 2).join(':');
    } else {
      return "N/A";
    }
  };

  console.log("Event Data in Step 1: ", eventData);
  console.log("Selected Ticket: ", selectedTicket);

  const eventDetails = {
    ...eventData,
    event: eventData?.event_id, // Ensure this matches your API response
    event_name: eventData?.event_name,
    venue: eventData?.venue_name,
    address: eventData?.venue_address,
    country: eventData?.event_origin_country,
    post_code: eventData?.venue_post_code,
    time: `${timeFormat(eventData?.start_time)} - ${timeFormat(eventData?.end_time)}`,
    ticket: selectedTicket?.id,
    price: ticketPrice,
    ticket_quantity: ticketQuantity,
    sub_total: ticketQuantity * ticketPrice,
    total: ticketQuantity * ticketPrice, // Adjust if there are additional fees or discounts
  };

  /**
   * Tracks when a user selects a specific ticket.
   */
  useEffect(() => {
    if (selectedTicket && eventData && eventData.event_name) {
      ReactGA.event('select_item', {
        currency: 'GBP',
        value: selectedTicket.price * ticketQuantity,
        items: [
          {
            item_id: selectedTicket.id,
            item_name: eventData.event_name,
            price: selectedTicket.price,
            quantity: ticketQuantity,
          },
        ],
      });

      console.log(`GA Event Sent: Selected Item "${selectedTicket.ticket_category}"`);
    }
  }, [selectedTicket, ticketQuantity, eventData]);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const options = { weekday: "short", day: "numeric", month: "long", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };

  // Handler functions to increment/decrement ticket quantity
  const handleIncrement = () => {
    dispatch(setSelectedQuantity(ticketQuantity + 1));
  };

  const handleDecrement = () => {
    if (ticketQuantity > 1) {
      dispatch(setSelectedQuantity(ticketQuantity - 1));
    }
  };

  // Handle loading and error states
  if (loading) return <p>Loading event details...</p>;
  if (error) return <p>Error loading event details. Please try again later.</p>;

  return (
    <>
      {eventData && (
        <div className="shadow-lg bg-gray-100 p-6 rounded-lg">
          <h2 className="font-poppins text-2xl md:text-3xl font-semibold text-center text-gray-800">
            {eventData?.event_name}
          </h2>
          <div className="flex flex-col sm:flex-row justify-between items-center mt-4">
            <div className="flex items-center gap-1">
              <CiLocationOn className="mr-1" size={20} />{' '}
              <p className="m-0 flex justify-center items-center">
                {eventData?.venue_name || 'N/A'}, {eventData?.venue_address || "N/A"}, {eventData?.event_origin_country || 'N/A'}, {eventData?.venue_post_code || "N/A"}
              </p>
            </div>
            <div className="flex items-center gap-1">
              <CiClock2 className="mr-1" size={20} />{' '}
              <p className="ms-3 mt-md-0 mb-0 flex justify-center items-center">
                {eventData?.start_time ? formatDate(eventData.start_date) : 'N/A'},{' '}
                {eventData?.start_time ? eventData.start_time.replace(/:00$/, '') : 'N/A'} Onwards
              </p>
            </div>
          </div>
          {eventData?.thumbnail_path && (
            <div className="rounded-xl overflow-hidden mt-4">
              <img
                src={eventData?.thumbnail_path || defaultImage}
                alt="Event Thumbnail"
                className="w-full h-auto object-cover max-h-80"
              />
            </div>
          )}
          <br />
          {/* Ticket Details */}
          <div className="mt-8">
            <h2 className="text-lg font-semibold mb-4">Ticket Details</h2>
            {/* Enhanced Ticket Description */}
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
              <div
                className="event-description text-gray-700 leading-relaxed space-y-4"
                dangerouslySetInnerHTML={{ __html: sanitizeDescription(eventData?.description) }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="shadow-lg bg-gray-100 p-5 rounded-lg mt-0">
        <h2 className="font-poppins text-lg md:text-xl font-semibold text-center text-gray-800">
          Select Your Tickets
        </h2>
        <div className="flex flex-col items-center mt-5">

          {/* Ticket Price */}
          <p className="font-poppins text-lg text-gray-700 mb-3">
            Price per Ticket: {ticketPrice === 0 ? "Free" : `£${ticketPrice.toFixed(2)}`}
          </p>

          {/* Ticket Quantity Selector */}
          <div className="flex items-center justify-center gap-4 mb-3">
            <button
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
              onClick={handleDecrement}
              disabled={ticketQuantity <= 1}
            >
              -
            </button>
            <span className="font-poppins text-lg text-gray-800">{ticketQuantity}</span>
            <button
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
              onClick={handleIncrement}
            >
              +
            </button>
          </div>

          {/* Total Price */}
          <p className="font-poppins text-lg text-gray-700 mb-6">
            Total Price: {ticketQuantity * ticketPrice === 0 ? "Free" : `£${(ticketQuantity * ticketPrice).toFixed(2)}`}
          </p>

          {/* Get Tickets Button */}
          <button
            className="bg-blue-600 text-white py-2 px-4 rounded-lg"
            onClick={() => handleGetTickets(eventDetails)}
          >
            {ticketPrice === 0 ? "Get Free Tickets" : "Get Tickets"}
          </button>
        </div>
      </div>
    </>
  );
};

const formatDescription = (description) => {
  if (!description) return '';
  return description
    .replace(/\n/g, '<br/>') // Handle line breaks
    .replace(/•/g, '<ul class="list-disc ml-5"><li>') // Handle bullet points
    .replace(/<\/li>(?!<li>)/g, '</li></ul>'); // Close unordered lists properly
};

TicketSelection.propTypes = {
  handleGetTickets: PropTypes.func.isRequired,
};

export default TicketSelection;
