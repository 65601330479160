import {
  USER_TICKETS_REQUEST,
  USER_TICKETS_SUCCESS,
  USER_TICKETS_FAIL,
  SINGLE_TICKET_REQUEST,
  SINGLE_TICKET_SUCCESS,
  SINGLE_TICKET_FAIL,
  SINGLE_TICKET_RESET,
} from "../constant/userTicketConstants";

const initialState = {
  userTicket: null,
  loading: false,
  error: null,
};

// Reducer for user's ticket list
export const ticketListReducer = (state = { tickets: [] }, action) => {
  switch (action.type) {
    case USER_TICKETS_REQUEST:
      return { loading: true, tickets: [] };
    case USER_TICKETS_SUCCESS:
      return { loading: false, tickets: action.payload };
    case USER_TICKETS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Reducer for a single ticket based on ticket id
export const singleUserTicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case SINGLE_TICKET_REQUEST:
      return { ...state, loading: true, error: null };
    case SINGLE_TICKET_SUCCESS:
      return { ...state, loading: false, userTicket: action.payload, error: null };
    case SINGLE_TICKET_FAIL:
      return { ...state, loading: false, error: action.payload };
    case SINGLE_TICKET_RESET:
      return { ...initialState };
    default:
      return state;
  }
};
