// src/utils/sanitizeDescription.js

/**
 * Sanitizes the event description by removing empty <p> elements.
 *
 * @param {string} html - The raw HTML string from React Quill.
 * @returns {string} - The sanitized HTML string.
 */
export const sanitizeDescription = (html) => {
    if (!html) return '';
  
    // Initialize DOMParser
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
  
    // Select all <p> elements
    const paragraphs = doc.querySelectorAll('p');
  
    paragraphs.forEach((p) => {
      // Check if the <p> element is empty or contains only whitespace and <br> tags
      const isEmpty = !p.textContent.trim() && !p.querySelector('img, video, iframe, embed, object, ul, ol');
  
      if (isEmpty) {
        p.remove();
      } else {
        // Optionally, remove excessive <br> tags within paragraphs
        p.innerHTML = p.innerHTML.replace(/(<br\s*\/?>\s*)+/g, '<br />');
      }
    });
  
    return doc.body.innerHTML;
  };
  