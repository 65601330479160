import React from 'react';

function Button({
    type="button",
    className,
    disabled=false,
    selected,
    sidebar,
    ...props
}) {

    const baseStyle = '';
    const sidebarSelected = props?.sidebarSelected ?? "text-white bg-blue-500 rounded-lg";
    const sidebarDefault = props?.sidebarDefault ?? "text-gray-700 hover:bg-gray-200 rounded-lg";

  return (
    <button 
        type={type}
        onClick={props?.onClick}
        className={sidebar ? `flex items-center mb-6 p-4 text-lg transition duration-300 ease-in-out w-full ${selected ? sidebarSelected : sidebarDefault}` : className ?? baseStyle}
        disabled={disabled}
    >
      {props?.children}
    </button>
  )
}

export default Button
