export const eventTypeChoices = [
    { key: 'trending', label: 'Trending Event in UK' },
    { key: 'community', label: 'Community Events' },
    { key: 'new_listing', label: 'New Listing' },
    { key: 'most_popular_free', label: 'Most Popular Free Events' },
    { key: 'latest', label: 'Latest Events' },
    { key: 'virtual', label: 'Virtual Events' },
    { key: 'festivals', label: 'Festivals and Carnivals' },
    { key: 'concerts', label: 'Concerts & Gigs' },
    { key: 'workshops', label: 'Workshops & Seminars' },
    { key: 'last_minute_deals', label: 'Last-Minute Deals' },
    { key: 'local_favorites', label: 'Local Favorites' },
    { key: 'nightlife', label: 'Nightlife & Parties' },
    { key: 'sports', label: 'Sports Events' },
    { key: 'cultural', label: 'Cultural & Heritage Events' },
    { key: 'art_exhibitions', label: 'Art & Exhibitions' },
    { key: 'business_networking', label: 'Business & Networking Events' },
    { key: 'popular_external', label: 'Popular Across Other Platforms' },
];