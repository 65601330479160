// src/components/layout/Navbar/CategoryNav.jsx

import React from 'react';
import { IoLocationSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../redux/auth/action/filtersActions';
import { useNavigate, useLocation } from 'react-router-dom';

function CategoryNav({ categories }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Access the current category filter from Redux store
  const currentCategoryFilter = useSelector((state) => state.filters.category);

  const handleCategoryClick = (categoryName) => {
    dispatch(setFilters({ category: categoryName }));
    if (location.pathname !== '/') {
      navigate('/');
    }
  };

  return (
    <div className="hidden lg:flex flex-wrap justify-center bg-white px-2">
      {categories.map((category) => {
        const isActive = currentCategoryFilter === category.category_name;
        return (
          <button
            key={category.category_id}
            onClick={() => handleCategoryClick(category.category_name)}
            className={`hidden md:flex p-1 px-2 md:px-1 lg:px-2 mx-1 mt-2 rounded-full text-xs font-poppins border-2 transition-colors duration-200 ${isActive
              ? 'bg-sunday_secondary text-white border-sunday_secondary'
              : 'bg-white text-sunday_info border-sunday_secondary'
              }`}
          >
            {category.category_name}
          </button>
        );
      })}
      <button
        onClick={() => window.open("https://maps.google.com", "_blank")}
        className="hidden md:flex text-sunday_info p-1 px-2 md:px-1 lg:px-2 mx-1 mt-2 rounded-full border-2 border-sunday_secondary text-xs font-poppins items-center"
      >
        <IoLocationSharp className="mr-1" /> Search Nearby Location
      </button>
    </div>
  );
}

export default CategoryNav;
