// src/redux/auth/action/fetchTicketActions.jsx

import apiClient from '../../../apiClient/apiClient';
import {
    FETCH_TICKETS_REQUEST,
    FETCH_TICKETS_SUCCESS,
    FETCH_TICKETS_FAILURE,
    FETCH_TICKETS_RESET
} from '../constant/fetchTicketsConstant';

export const fetchTicketByEventId = (eventId) => async (dispatch) => {
    dispatch({ type: FETCH_TICKETS_REQUEST });

    try {
        // Fetch tickets specific to the event from the /events/:eventID/tickets endpoint
        const { data } = await apiClient.get(`/events/${eventId}/tickets`);

        console.log("[DEBUG] Fetched Tickets Data:", data);
        console.log("[DEBUG] Event ID from Params:", eventId);

        // Assuming data is an array of tickets for the event
        dispatch({
            type: FETCH_TICKETS_SUCCESS,
            payload: data // Dispatch the tickets array directly
        });
    } catch (error) {
        dispatch({
            type: FETCH_TICKETS_FAILURE,
            payload: error.response?.data?.detail || 'Failed to fetch tickets',
        });
    }
};

export const resetFetchTicket = () => ({
    type: FETCH_TICKETS_RESET,
});
