// src/redux/auth/action/userTicketActions.jsx

import {
    USER_TICKETS_REQUEST,
    USER_TICKETS_SUCCESS,
    USER_TICKETS_FAIL,
    SINGLE_TICKET_REQUEST,
    SINGLE_TICKET_SUCCESS,
    SINGLE_TICKET_FAIL,
    SINGLE_TICKET_RESET
} from "../constant/userTicketConstants";
import apiClient from "../../../apiClient/apiClient";

// Fetch a single user ticket by ticket ID
export const getUserTicketByTicketId = (ticketId) => async (dispatch) => {
    try {
        dispatch({ type: SINGLE_TICKET_REQUEST });

        console.log("Trying to get single ticket by ticket id ", ticketId);

        // Get token from localStorage
        const token = localStorage.getItem("access") || sessionStorage.getItem("access");

        // Request headers with authorization token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        // Fetch the user's tickets
        const { data: tickets } = await apiClient.get(`/user/tickets/`, config);

        // Filter tickets that match the provided ticketId
        const matchingTickets = tickets.filter(ticket => ticket.ticket === Number(ticketId));

        console.log("Matching user tickets:", matchingTickets);

        if (matchingTickets.length > 0) {
            // Sort the matching tickets by ticket.id in descending order
            const sortedTickets = matchingTickets.sort((a, b) => b.id - a.id);

            // Select the first ticket in the sorted array (highest ticket.id)
            const latestTicket = sortedTickets[0];

            console.log("Latest matching user ticket:", latestTicket);

            dispatch({
                type: SINGLE_TICKET_SUCCESS,
                payload: latestTicket,
            });
        } else {
            console.log("No user ticket found with the provided ticket ID");
            dispatch({
                type: SINGLE_TICKET_FAIL,
                payload: "Ticket not found for the provided ticket ID",
            });
        }
    } catch (error) {
        console.log("Error in getUserTicketByTicketId:", error);

        dispatch({
            type: SINGLE_TICKET_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
};



// Fetch user's purchased tickets (existing function)
export const getUserTickets = () => async (dispatch) => {
    try {
        dispatch({ type: USER_TICKETS_REQUEST });

        // Get token from localStorage
        const token = localStorage.getItem("access") || sessionStorage.getItem("access");

        // Request headers with authorization token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        // Fetch the purchased tickets
        const { data: tickets } = await apiClient.get("/user/tickets/", config);

        // Fetch event details for each ticket
        const updatedTickets = await Promise.all(
            tickets.map(async (ticket) => {
                try {
                    const { data: eventData } = await apiClient.get(`/events/${ticket.event}`, config);
                    return { ...ticket, ...eventData }; // Merge ticket and event data
                } catch (error) {
                    console.error(`Error fetching event ${ticket.event}`, error);
                    return ticket; // Return original ticket if event fetch fails
                }
            })
        );

        console.log(updatedTickets);

        // Dispatch the successful result with updated ticket data
        dispatch({
            type: USER_TICKETS_SUCCESS,
            payload: updatedTickets,
        });
    } catch (error) {
        // Dispatch failure if an error occurs
        dispatch({
            type: USER_TICKETS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const resetSingleTicket = () => ({
    type: SINGLE_TICKET_RESET,
});
