// src/components/pages/readyfortickets/TicketSection.jsx

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTicket, resetSelectedTicket, setSelectedQuantity } from "../../redux/auth/action/selectedTicketActions";
import PropTypes from 'prop-types';

const TicketSection = ({ tickets }) => {
  const dispatch = useDispatch();

  // Access Redux store's selectedTicket and ticketQuantity
  const selectedTicket = useSelector((state) => state.selectedTicket.selectedTicket);
  const ticketQuantity = useSelector((state) => state.selectedTicket.ticketQuantity);

  /**
   * Handles the selection of a ticket when the "+" button is clicked.
   * @param {Object} ticket - The ticket object to select/increment.
   */
  const handleIncrement = (ticket) => {
    if (selectedTicket && selectedTicket.id === ticket.id) {
      // If the ticket is already selected, increment the quantity
      dispatch(setSelectedQuantity(ticketQuantity + 1));
    } else {
      // Select the new ticket and set quantity to 1
      dispatch(setSelectedTicket(ticket));
    }
  };

  /**
   * Handles the decrement of the ticket quantity.
   * @param {Object} ticket - The ticket object to decrement.
   */
  const handleDecrement = (ticket) => {
    if (selectedTicket && selectedTicket.id === ticket.id) {
      if (ticketQuantity > 1) {
        dispatch(setSelectedQuantity(ticketQuantity - 1));
      } else {
        // If quantity reaches 0, deselect the ticket
        dispatch(resetSelectedTicket());
      }
    }
  };

  /**
   * Handles the deselection of a ticket via the "Deselect" button.
   */
  const handleDeselect = () => {
    dispatch(resetSelectedTicket());
  };

  return (
    <div className="space-y-4">
      {tickets.map((ticket) => {
        const isSelected = selectedTicket && selectedTicket.id === ticket.id;
        const isAnyTicketSelected = !!selectedTicket;

        // Determine if this ticket's picker should be disabled
        const isPickerDisabled = isAnyTicketSelected && !isSelected;

        return (
          <div
            key={ticket.id}
            className={`ticket-item border-[rgba(0,0,0,0.1)] border-2 p-4 rounded-lg mb-4 flex justify-between items-center ${isSelected ? 'border-blue-500' : 'border-gray-300'
              }`}
          >
            <div>
              <p className="font-semibold">
                {ticket.ticket_category.charAt(0).toUpperCase() + ticket.ticket_category.slice(1)}
              </p>
              <p>{ticket.ticket_description}</p>
              <p className="font-semibold">
                Price: {ticket.price === 0 ? "Free" : `£${parseFloat(ticket.price).toFixed(2)}`}
              </p>
              {/* Add more ticket info as needed */}
            </div>
            <div className="flex items-center gap-2">
              {/* Deselect Button */}
              {isSelected && (
                <button
                  className="ml-2 bg-red-500 text-white px-2 py-1 rounded"
                  onClick={handleDeselect}
                >
                  Deselect
                </button>
              )}

              {/* Quantity Picker */}
              <div className="flex items-center">
                <button
                  className="bg-gray-300 text-gray-700 px-2 py-1 rounded disabled:bg-gray-200 disabled:cursor-not-allowed"
                  onClick={() => handleDecrement(ticket)}
                  disabled={!isSelected || ticketQuantity <= 0}
                >
                  -
                </button>
                <span className="px-2">{isSelected ? ticketQuantity : 0}</span>
                <button
                  className="bg-gray-300 text-gray-700 px-2 py-1 rounded disabled:bg-gray-200 disabled:cursor-not-allowed"
                  onClick={() => handleIncrement(ticket)}
                  disabled={isPickerDisabled}
                >
                  +
                </button>
              </div>

            </div>
          </div>
        );
      })}
    </div>
  );
};

TicketSection.propTypes = {
  tickets: PropTypes.array.isRequired,
};

export default TicketSection;
