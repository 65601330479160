import React from 'react'
import { Helmet } from 'react-helmet'

function HomeHelmet() {
  return (
    <Helmet>
        <title>EventfullSunday.co.uk : Events That Connect You with Your Roots - Bringing Your Homeland’s Events Closer Than Ever Before.</title>
          
        <meta 
        name="description" 
        content="Explore a wide range of events, including live music, sports, theatre, stand-up comedy, and cultural festivals, all organized by communities from across the globe. EventfullSunday.co.uk helps you connect with events created by people from your hometown or origin country. Enjoy personalized event recommendations, seamless booking, and no mandatory sign-ups." 
        />

        <meta 
        name="keywords" 
        content="events in the UK, community events, cultural events, live music, sports events, UK theatre, stand-up comedy, cultural festivals, event discovery platform, personalized event recommendations, no sign-up event discovery, UK event platform" 
        />
        <meta property="og:url" content="https://eventfullsunday.co.uk/" />

        <link rel="canonical" href="https://eventfullsunday.co.uk/" />
    </Helmet>
  )
}

export default HomeHelmet
