// src/utils/addToCalendar.js

/**
 * Adds one day to a given date string in "YYYY-MM-DD" format.
 *
 * @param {string} dateStr - The date string to add a day to.
 * @returns {string} - The new date string with one day added.
 */
const addOneDayToDate = (dateStr) => {
    const date = new Date(`${dateStr}T00:00:00Z`); // Initialize at UTC midnight
    date.setUTCDate(date.getUTCDate() + 1); // Add one day
    const yyyy = date.getUTCFullYear();
    const mm = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months start at 0
    const dd = String(date.getUTCDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
};

/**
 * Generates a Google Calendar event creation URL based on event details.
 *
 * @param {string} eventTitle - The title of the event.
 * @param {string} eventDescription - A description of the event.
 * @param {string} startDate - The start date of the event in YYYY-MM-DD format.
 * @param {string} startTime - The start time of the event in HH:MM:SS format.
 * @param {string|null} endDate - The end date of the event in YYYY-MM-DD format. If null, defaults to startDate + 1 day.
 * @param {string|null} endTime - The end time of the event in HH:MM:SS format. If null, defaults to startTime + 2 hours.
 * @param {string} location - The location of the event.
 * @returns {string} - The formatted Google Calendar URL.
 */
export const generateGoogleCalendarURL = (
    eventTitle,
    eventDescription,
    startDate,
    startTime,
    endDate,
    endTime,
    location
) => {
    const formatDate = (dateStr, timeStr) => {
        const date = new Date(`${dateStr}T${timeStr}Z`); // Ensure UTC time
        const yyyy = date.getUTCFullYear();
        const mm = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months start at 0
        const dd = String(date.getUTCDate()).padStart(2, '0');
        const hh = String(date.getUTCHours()).padStart(2, '0');
        const min = String(date.getUTCMinutes()).padStart(2, '0');
        const ss = String(date.getUTCSeconds()).padStart(2, '0');
        return `${yyyy}${mm}${dd}T${hh}${min}${ss}Z`;
    };

    // Set endDate to startDate + 1 day if endDate is null
    const finalEndDate = endDate ? endDate : addOneDayToDate(startDate);

    // Default endTime to startTime + 2 hours if null
    let finalEndTime;
    if (endTime) {
        finalEndTime = endTime;
    } else {
        const start = new Date(`${startDate}T${startTime}Z`);
        const end = new Date(start.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours
        finalEndTime = `${String(end.getUTCHours()).padStart(2, '0')}:${String(end.getUTCMinutes()).padStart(2, '0')}:00`;
    }

    const start = formatDate(startDate, startTime);
    const end = formatDate(finalEndDate, finalEndTime);

    const params = new URLSearchParams({
        action: 'TEMPLATE',
        text: eventTitle,
        dates: `${start}/${end}`,
        details: eventDescription,
        location: location,
        sf: 'true',
        output: 'xml',
    });

    return `https://calendar.google.com/calendar/render?${params.toString()}`;
};

export const handleAddToCalendar = (data) => {

    const calendarURL = generateGoogleCalendarURL(
      data.event_name || "Event",
      stripHtmlTags(data.description) || "No description provided.",
      data.start_date,
      data.start_time,
      data.end_date,
      data.end_time,
      `${data.venue_name || "N/A"}, ${data.venue_address || "N/A"}, ${data.venue_post_code || "N/A"}, ${data.event_origin_country || "N/A"}`
    );

    window.open(calendarURL, "_blank", "noopener,noreferrer");
  };

  /**
   * Utility function to strip HTML tags from a string.
   *
   * @param {string} html - The HTML string to strip.
   * @returns {string} - The plain text string.
   */
  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };
