// src/components/layout/Navbar/Navbar.jsx

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import event from "../../assets/images/eventfullsunday.png";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from '../../redux/auth/action/profileActions';
import { AnimatePresence } from "framer-motion";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchForm from "./Search/SearchForm";
import CategoryNav from "./CategoryNav";
import AuthLinks from "./MenuItems/AuthLinks";
import ProfileLinks from "./MenuItems/ProfileLinks";
import MenuNav from "./MenuItems/MenuNav";
import MobileMenu from "./MenuItems/MobileMenu";
import apiClient from '../../apiClient/apiClient'; 

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [categories, setCategories] = useState([]); // State for categories

  const dispatch = useDispatch();

  const { loading, profile } = useSelector((state) => state.profile);
  const firstName = profile?.full_name?.split(" ")[0];
  const token = localStorage.getItem("access") || sessionStorage.getItem("access");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await dispatch(getProfile());
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    if (token) {
      fetchProfile();
    }

    // Fetch categories
    const fetchCategories = async () => {
      try {
        const response = await apiClient.get("/category/");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [dispatch, token]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <nav className="relative bg-white shadow-md">
      <div className="flex max-w-[1200px] mx-auto px-4 justify-between items-center py-4 min-h-[120px] sm:min-h-auto">
        <Link to="/" className="min-w-[80px] mr-4">
          <img src={event} className="w-20 h-20 rounded-md" alt="Event" />
        </Link>

        {/* Middle section */}
        <div className="hidden md:flex flex-grow flex-col items-center w-full md:w-auto lg:w-auto">
          <div className="flex items-center justify-center w-full max-w-2xl">
            <SearchForm />
          </div>
          <CategoryNav categories={categories} />
        </div>

        {/* Search form for mobile view */}
        <div className="flex-grow mx-4 md:hidden">
          <SearchForm />
        </div>

        <MenuNav isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />

        <div className="hidden md:flex items-center gap-6">
          {localStorage.getItem("access") || sessionStorage.getItem("access")
            ? <ProfileLinks loading={loading} firstName={firstName} />
            : <AuthLinks />}
        </div>
      </div>

      {/* Mobile menu */}
      <AnimatePresence>
        {isMenuOpen && <MobileMenu categories={categories} loading={loading} firstName={firstName} />}
      </AnimatePresence>

      {/* Toast notifications */}
      <ToastContainer position="top-right" autoClose={5000} />
    </nav>
  );
};

export default Navbar;
