import apiClient from "../../../apiClient/apiClient";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const passwordResetRequest = () => ({ type: "PASSWORD_RESET_REQUEST" });
export const passwordResetSuccess = (message) => ({
  type: "PASSWORD_RESET_SUCCESS",
  payload: message,
});
export const passwordResetFailure = (error) => ({
  type: "PASSWORD_RESET_FAILURE",
  payload: error,
});

//For reset password
export const sendResetPassword = (email, redirect) => async (dispatch) => {
  dispatch(passwordResetRequest());
  try {
    const token = localStorage.getItem("access") || sessionStorage.getItem("access");
    console.log(token, 'getting the token');

    const response = await apiClient.post('/password-reset/', { email });

    console.log(response);

    dispatch(passwordResetSuccess(response.data.message));

    if (redirect) {
      // Navigate to password reset email confirmation page with email as a query parameter
      window.location.href = `/passwordResetConfirmation?email=${encodeURIComponent(email)}`;
    }

  } catch (error) {
    const errorMessage = error.response?.data?.error || 'An error occurred.';
    dispatch(passwordResetFailure(errorMessage));

    // Display a toast if an account with entered email is not found
    toast.error(<p className="m-2">No account was found matching the email you entered.</p>);
  }
};