// Profile.jsx
import React from "react";
import Profile from "./Profile/Profile";

const Updates = () => {
  return (
    <div className="w-full bg-white p-6 rounded-lg shadow-lg">
      <div className="flex flex-col items-center">
        <Profile />
      </div>
    </div>
  );
};

export default Updates;
