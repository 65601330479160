// src/components/pages/readyfortickets/Confirmation.jsx

import React, { useEffect, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { useDispatch, useSelector } from "react-redux";
import { getUserTicketByTicketId, resetSingleTicket } from "../../redux/auth/action/userTicketActions";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Shimmer from "../Shimmer";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import apiClient from "../../apiClient/apiClient";

const Confirmation = ({ eventDetails }) => {
  const dispatch = useDispatch();
  const { selectedTicket } = useSelector((state) => state.selectedTicket);
  const { userTicket, loadingUserTicket, error } = useSelector((state) => state.singleUserTicket);

  // Local state to prevent multiple POST requests
  const [hasAttemptedPurchase, setHasAttemptedPurchase] = useState(false);

  useEffect(() => {
    const confirmTicket = async () => {
      console.log("================================\n[TESTING] Start of statement");

      console.log("Selected ticket ID: ", selectedTicket.id);

      try {
        const accessToken = localStorage.getItem("access") || sessionStorage.getItem("access");
        if (!accessToken) {
          if (!toast.isActive(5, "ticketPurchase")) {
            toast.error("Please log in to continue.", {
              toastId: 5,
              containerId: "ticketPurchase"
            });
          }
          return;
        }

        if (loadingUserTicket) {
          console.log("[TESTING] Waiting to fetch ticket...");
          return;
        }

        // This condition should never be true any more 
        // as ticket is being reset on component unmount.
        // But keeping just in case.

        // if (hasAttemptedPurchase && userTicket && userTicket.ticket === selectedTicket.id) {
        //   console.log("[TESTING] Ticket already fetched and matches.");
        //   // Proceed to display confirmation
        //   if (!toast.isActive(2, "ticketPurchase")) {
        //     toast.success("Ticket purchase confirmed!", {
        //       toastId: 2
        //     });
        //   }
        //   return;
        // }

        if (error) {
          console.log("[TESTING] An error occurred:", error);
          return;
        }

        // Handle free ticket purchase
        if (!hasAttemptedPurchase && eventDetails.price === 0) {
          console.log("[TESTING] Ticket is free, sending purchase request...");

          const formData = {
            event: eventDetails.event, // Event ID
            ticket: selectedTicket.id, // Ticket ID
            ticket_quantity: eventDetails.ticket_quantity,
            sub_total: eventDetails.sub_total,
            total: eventDetails.total,
          };

          try {
            // Send POST request to create the free ticket
            await apiClient.post("api/ticket-purchase/", formData, {
              headers: { Authorization: `Bearer ${accessToken}` },
            });

            ReactGA.event('purchase', {
              currency: 'GBP',
              value: eventDetails.total, // Should be 0 for free tickets
              items: [
                {
                  item_id: selectedTicket.id,
                  item_name: eventDetails.event_name,
                  price: eventDetails.price,
                  quantity: eventDetails.ticket_quantity,
                },
              ],
            });

            console.log(`GA Event Sent: Free Purchase for "${eventDetails.event_name}" with value £${eventDetails.total}`);

            if (!toast.isActive(1, "ticketPurchase")) {
              toast.success("Free ticket confirmed!", {
                toastId: 1,
                containerId: "ticketPurchase"
              });
            }

            // Update the flag to prevent multiple POST requests
            setHasAttemptedPurchase(true);

            // Fetch the newly created ticket
            dispatch(getUserTicketByTicketId(selectedTicket.id));
          } catch (postError) {
            console.error("Error purchasing free ticket:", postError);
            if (!toast.isActive(3, "ticketPurchase")) {
              toast.error("Ticket confirmation failed. Please try again.", {
                toastId: 3,
                containerId: "ticketPurchase"
              });
            }
          }

          return; // Exit early to wait for the ticket to be fetched

        }

        console.log(eventDetails.price, eventDetails.price !== 0);

        // Display toast if user got a paid ticket
        if (eventDetails.price !== 0) {
          if (!toast.isActive(7, "ticketPurchase")) {
            toast.success("Ticket purchase confirmed!", {
              toastId: 7,
              containerId: "ticketPurchase"
            });
          }
        }

        if (!userTicket) {
          console.log("[TESTING] Attempting to fetch user ticket...");
          dispatch(getUserTicketByTicketId(selectedTicket.id));
        }

      } catch (generalError) {
        console.error("Error confirming ticket", generalError);
        if (!toast.isActive(3, "ticketPurchase")) {
          toast.error("Ticket confirmation failed. Please try again.", {
            toastId: 3,
            containerId: "ticketPurchase"
          });
        }
      } finally {
        console.log("[TESTING] Hit the finally statement");
        console.log("================================\n");
      }
    };

    confirmTicket();

    // Cleanup user ticket on unmount
    return () => {
      dispatch(resetSingleTicket());
    };

  }, [
    dispatch,
    selectedTicket.id,
    hasAttemptedPurchase,
    loadingUserTicket,
    error,
    eventDetails.price,
  ]);

  // Confirm that a purchase has been completed successfully
  useEffect(() => {
    if (userTicket && userTicket.ticket === selectedTicket.id) {
      ReactGA.event('purchase_confirmation', {
        transaction_id: userTicket.reference_id,
        currency: 'GBP',
        value: eventDetails.total,
        items: [
          {
            item_id: selectedTicket.id,
            item_name: eventDetails.event_name,
            price: eventDetails.price,
            quantity: eventDetails.ticket_quantity,
          },
        ],
      });

      console.log(`GA Event Sent: Purchase Confirmation for "${eventDetails.event_name}"`);
    }
  }, [userTicket, selectedTicket, eventDetails]);

  // Handle potential errors by displaying an error message and a navigation link
  // if (!loadingUserTicket && error) {
  //   return (
  //     <div className="shadow-lg bg-gray-100 p-6 rounded-lg">
  //       <ToastContainer position="top-right" containerId={"ticketPurchase"} autoClose={3000} />
  //       <p className="text-center mt-10 text-red-500">Error: {error}</p>
  //       <div className="flex justify-center mt-4">
  //         <Link to={`/event/${selectedTicket.event}`} className="text-blue-600 underline">
  //           Go Back to Event Details
  //         </Link>
  //       </div>
  //     </div>
  //   );
  // }

  // Create data for QR code only if userTicket is available
  const qrData = userTicket && userTicket.ticket === selectedTicket.id
    ? JSON.stringify({
      reference_id: userTicket.reference_id,
      event_id: eventDetails.event,
      event_name: eventDetails.event_name,

      venue: eventDetails.venue_name,
      address: eventDetails.venue_address ? eventDetails.venue_address : "N/A",

      start_time: eventDetails.start_time,
      end_time: eventDetails.end_time,

      ticket_quantity: eventDetails.ticket_quantity,
      ticket_category: userTicket.ticket_category.charAt(0).toUpperCase() + userTicket.ticket_category.slice(1),

      sub_total: eventDetails.sub_total,
      total: eventDetails.total,

      date_purchased: new Date().toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }),
    })
    : "";

  console.log("Ticket Details in confirmation page:\n", eventDetails);

  return (
    <div className="shadow-lg bg-gray-100 p-6 rounded-lg">
      <ToastContainer position="top-right" containerId={"ticketPurchase"} autoClose={3000} />
      {loadingUserTicket || !userTicket || (userTicket && userTicket.ticket !== selectedTicket.id) ? (
        <div className="flex justify-center my-4">
          <Shimmer width={"256px"} height={"256px"} />
        </div>
      ) : (
        <>
          <h2 className="font-poppins text-lg md:text-xl font-semibold text-center text-gray-800">
            Thank you for your Purchase
          </h2>
          <p className="font-poppins text-base text-center text-gray-700 mt-2">
            Your purchase reference number: <strong>{userTicket && userTicket.ticket === selectedTicket.id ? userTicket.reference_id : "N/A"}</strong>
          </p>
          <div className="flex flex-col items-center mt-6">
            <p className="font-poppins text-base text-gray-700 mb-4">Your QR Ticket:</p>
            {/* Commenting out old QR Code library */}
            {/* <QRCodeCanvas
              value={qrData}
              size={256}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"H"}
              includeMargin={true}
            /> */}

            {/* Testing with QR Code from backend */}

            {userTicket && userTicket.ticket === selectedTicket.id ? (
              <img width="350px" src={userTicket?.qr_code} alt="QR Code" />
            ) : (
              <div className="flex justify-center my-4">
                <Shimmer width={"256px"} height={"256px"} />
              </div>
            )}

          </div>
          <div className="mt-6 text-center">
            <p className="font-poppins text-sm md:text-base text-gray-700 leading-7 max-w-2xl mx-auto">
              Thank you for choosing us and purchasing your ticket through our
              website. Please check your email for your recent purchased ticket
              information & QR code. You will need to show the QR code at the
              entrance for entry.
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default Confirmation;
